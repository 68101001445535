import {pubkeyToAddress, encodeSecp256k1Pubkey} from '@cosmjs/amino'
const ethers = require('ethers')
const ethTx2cosmosAddress = async (tx, prefix) => {
  const expandedSig = {
    r: tx.r,
    s: tx.s,
    v: tx.v
  }
  const signature = ethers.utils.joinSignature(expandedSig)
  const txData = {
    nonce: tx.nonce,
    gasPrice: ethers.BigNumber.from(tx.gasPrice),
    gasLimit: ethers.BigNumber.from(tx.gasLimit),
    to: tx.to,
    value: ethers.BigNumber.from(tx.value),
    data: tx.data,
    chainId: tx.chainId
  }
  const rsTx = await ethers.utils.resolveProperties(txData)
  const raw = ethers.utils.serializeTransaction(rsTx) // returns RLP encoded tx
  const msgHash = ethers.utils.keccak256(raw) // as specified by ECDSA
  const msgBytes = ethers.utils.arrayify(msgHash) // create binary hash
  const recoveredPubKey = ethers.utils.recoverPublicKey(msgBytes, signature)
  // const pubKeyBytes = ethers.utils.arrayify(recoveredPubKey)
  const PubKeyCompressed = ethers.utils.computePublicKey(recoveredPubKey, true)
  const PubKeyCompressedBytes = ethers.utils.arrayify(PubKeyCompressed)
  // const ethAddress = ethers.utils.recoverAddress(msgBytes, signature)
  // console.log('ethAddress', ethAddress)
  // console.log('joltAddress', pubkeyToAddress(encodeSecp256k1Pubkey(PubKeyCompressedBytes), prefix))
  return pubkeyToAddress(encodeSecp256k1Pubkey(PubKeyCompressedBytes), prefix)
}
export default ethTx2cosmosAddress