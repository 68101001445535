import React, { useState, useEffect } from 'react'
import './Home.css'
import imageLogo from '@/assets/joltify.svg'
import imageTwitter from '@/assets/Twitter.svg'
import imageMedium from '@/assets/Medium.svg'
import imageDiscord from '@/assets/Discord.svg'
import imageTelegram from '@/assets/Telegram.svg'
import imageSignout from '@/assets/SignOut.svg'
import imageBridge from '@/assets/Bridge.svg'
import imagePools from '@/assets/Pools.svg'
import imageAssets from '@/assets/Assets.svg'
import imageTabBg from '@/assets/tab-bg.svg'
import imgKeplr1 from '@/assets/Keplr1.svg'
import imgKeplr2 from '@/assets/Keplr2.svg'
import imgMetamask from '@/assets/metamask.svg'
import imgLoading from '@/assets/loading.gif'
import imgBigDipper from '@/assets/big-dipper.svg'
import imgLink from '@/assets/link.svg'
import imgStaking from '@/assets/Staking.svg'
import imgStake from '@/assets/Stake.svg'

import {chainConfigBsc, chainConfigKeplr} from '../../configs/index'

import {Outlet, useNavigate, useLocation, useParams} from 'react-router-dom'

import '@/extends/tofloor'

// import Bridge from '@/components/Bridge/Bridge'

import { SigningStargateClient } from '@cosmjs/stargate';

const ethers = require('ethers')
const axios = require('axios')

const termsUrl = require('../../assets/Joltify_website-terms-and-conditions-of-use-services.pdf').default

axios.defaults.timeout = 30000

const chainStatus = process.env.REACT_APP_CHAIN_STATUS


// const chainId = chainConfigKeplr.chainId // 'joltifyChain'
let chainId
const rpc = chainConfigKeplr.rpc // 'https://rpc.joltify.io'
const rest = chainConfigKeplr.rest // 'https://rest.joltify.io'
let joltifyChainWss = 'wss://wss.joltify.io/websocket'
let explorerUrl = 'https://test.blockscan.joltify.io/'
if ('testnet'===chainStatus) {
  joltifyChainWss = 'wss://wss.joltify.io/websocket'
  explorerUrl = 'https://test.blockscan.joltify.io/'
}

const stakingUrl = 'https://appjolt.opdsdemo.com.au/'

let client
let joltifyWsClient

const jusdAddress = chainConfigBsc.tokens[0].address

let arr = []
for (let i = 0; i < 99; i++) {
  arr.push(i)
}

let menuTabInfos = []
let domTabBg
let tabBgHeight
let domMenuTabs
let metamaskChainId

let walletListening = false
let bscConnected = false
let keplrConnected = false

let intervalMetamask // interval get balances, after logout, set to undefined
let intervalKeplr

let joltifyTxListening = false
let joltifyAccount
let joltifyWsRecconTimeout = 5000

let provider
let oldMetamaskChainId = '0x0'

const Home = () => {
  const keplr = window.keplr
  let ethereum = window.ethereum

  // console.log('Home')
  const navigate = useNavigate()
  const location = useLocation()
  // console.log('location', location)

  const [tabIndex, updateTabindex] = useState(0)
  const [joltifyChainInfo, updateJoltifyChainInfo] = useState({ account: undefined, joltify2bscAddress: undefined, client: undefined, balances: undefined })
  const [bscInfo, updateBscInfo] = useState({ account: undefined, balances: undefined, jusdContract: undefined, bscSigner: undefined })
  const [loading, updateLoading] = useState(false)
  const [loadingBsc, updateLoadingBsc] = useState(false)
  const [currentChainId, setCurrentChainId] = useState(oldMetamaskChainId)

  const [outletPassback, setOutletPassback] = useState('')

  if (!walletListening) { // otherwist, it might watch for many times
    walletListening = true
    // console.log('walletListening')

    if (keplr) {
      window.addEventListener("keplr_keystorechange", () => {
        if (keplrConnected) {
          signoutClicked()
          connectClicked()
        }
      })
    }

    if (ethereum) {
      // console.log('home ethereum', ethereum)
      provider = new ethers.providers.Web3Provider(ethereum)
      ethereum.on('connect', connectInfo=>{
        // console.log('ethereum.on connect', connectInfo)
        metamaskChainId = connectInfo.chainId // hex string
        // console.log('connectInfo.chainId', connectInfo.chainId)
        oldMetamaskChainId = connectInfo.chainId
        setCurrentChainId(connectInfo.chainId)
        console.log('home ethereum.on connect connectInfo.chainId', connectInfo.chainId)
      })
    
      ethereum.on('chainChanged', chainId=>{
        // console.log('ethereum.on chainChanged', chainId)
        ethereum = window.ethereum
        metamaskChainId = chainId
        console.log('chainId', chainId)
        oldMetamaskChainId = chainId
        setCurrentChainId(chainId)
        if (parseInt(metamaskChainId)===parseInt(chainConfigBsc.chainParam.chainId)) {
          // window.location.reload()
          return
        }
        signoutBscClicked() // logout metamtsk
      })
    
      ethereum.on('accountsChanged', accounts=>{
        // console.log('ethereum.on accountsChanged', accounts)
        if ( !bscConnected || parseInt(metamaskChainId)!==parseInt(chainConfigBsc.chainParam.chainId) ) {
          // console.log('need not to connect agtin', bscConnected, parseInt(metamaskChainId), parseInt(chainConfigBsc.chainParam.chainId))
          return
        }
        signoutBscClicked()
        connectBscClicked()
      })
    } else {
      console.log('home !ethereum')
    }
  }

  const getJoltifyInfo = async () => {
    if (!chainId) {
      try {
        const abciInfo = await axios.get(`${rpc}/status`)
        if (200!==abciInfo.status) {
          alert('get chain info failed')
          updateLoading(false)
          return
        }
        chainId = abciInfo.data.result.node_info.network
        console.log('chainId:', chainId)
        chainConfigKeplr.chainId = chainId
      } catch (e) {
        alert('get chain info failed')
        updateLoading(false)
        console.log('get abciInfo error', e)
        return
      }
    }
    keplr.enable(chainId).then(async ()=>{ // undefined
      // console.log('enableSuccess')
      const offlineSigner = window.getOfflineSigner(chainId)
      const accounts = await offlineSigner.getAccounts()
      const account = accounts[0].address
      joltifyAccount = account
      client = await SigningStargateClient.connectWithSigner(rpc, offlineSigner)
      axios.get(rest + '/cosmos/bank/v1beta1/balances/' + account).then(({data}) => {
        const balances = {}
        data.balances.map(item => {
          balances[item.denom] = ethers.utils.parseEther(item.amount).div(ethers.utils.parseEther('1'))
        })
        const joltify2bscAddress = ethers.utils.computeAddress(accounts[0].pubkey)
        if (!balances['JOLT']) { // otherwise, it might pass undefined, and cause error
          balances['JOLT'] = ethers.utils.parseEther('0')
        } 
        if (!balances['JUSD']) {
          balances['JUSD'] = ethers.utils.parseEther('0')
        }
        updateJoltifyChainInfo({
          account: account, client: client, balances: balances, joltify2bscAddress: joltify2bscAddress
        })
        keplrConnected = true
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        updateLoading(false)
      })
      if (!joltifyTxListening) {
        joltifyWsClient = new WebSocket(joltifyChainWss)
        joltifyTxListening = true
        joltifyWsClient.onopen = () => {
          joltifyWsClient.send(JSON.stringify({
            jsonrpc: "2.0",
            method: "subscribe",
            id: 0,
            params: {
              query: "tm.event='Tx'"
            }
          }))
        }
        joltifyWsClient.onmessage = ({data}) => {
          const json = JSON.parse(data)
          if (!json.result.events || !joltifyAccount) {
            return
          }
          const recipients = json.result.events['transfer.recipient']
          if (recipients) {
            for (let i=0; i<recipients.length; i++) {
              if (joltifyAccount===recipients[i]) {
                getJoltifyInfo()
                break
              }
            }
          }
          const senders = json.result.events['transfer.sender']
          if (senders) {
            for (let i=0; i<senders.length; i++) {
              if (joltifyAccount===senders[i]) {
                getJoltifyInfo()
                break
              }
            }
          }
        }
        joltifyWsClient.onclose = (res) => {
          console.log('ws.onclose', res)
          setTimeout(()=>{
            joltifyWsClient = new WebSocket(joltifyChainWss)
            joltifyWsRecconTimeout += 1000
          }, joltifyWsRecconTimeout)
        }
      }
    }).catch(enableFailed=>{
      console.error('enableFailed3', enableFailed.toString()) // object, but can not get it's keys
      if (-1===enableFailed.toString().indexOf('There is no chain info for')) {
        updateLoading(false)
        return
      }
      signoutClicked()
      // add chain to keplr
      keplr.experimentalSuggestChain(chainConfigKeplr).then(()=>{ // undefined
        // console.log('keplrChainAdded')
        getJoltifyInfo()
      }).catch(keplrAddChainFailed=>{
        updateLoading(false)
        console.error('keplrAddChainFailed', keplrAddChainFailed)
      })
    })
  }

  const connectClicked = async () => {
    return
    if (!keplr) {
      alert('Please install keplr extension'); return
    }
    updateLoading(true)
    getJoltifyInfo()
  }
  const signoutClicked = () => {
    updateJoltifyChainInfo({ account: undefined, client: undefined, balances: undefined })
    joltifyAccount = undefined
    keplrConnected = false
    clearInterval(intervalKeplr);intervalKeplr=undefined
  }

  const getBscInfo = async () => {
    // console.log('getBscInfo')
    if ( parseInt(metamaskChainId) !== parseInt(chainConfigBsc.chainParam.chainId) ) {
      signoutBscClicked()
      ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainConfigBsc.chainParam.chainId }],
      }).then(()=>{ // null
        metamaskChainId = chainConfigBsc.chainParam.chainId // required!
        // console.log('chainConfigBsc.chainParam.chainId', chainConfigBsc.chainParam.chainId)
        oldMetamaskChainId = chainConfigBsc.chainParam.chainId
        setCurrentChainId(chainConfigBsc.chainParam.chainId)
        // console.log('switchSuccess')
        getBscInfo()
      }).catch(switchError=>{
        if (4902 !== switchError.code) {
          console.error('switchError', switchError)
          updateLoadingBsc(false)
          return
        }
        ethereum.request({ // https://docs.metamask.io/guide/rpc-api.html#unrestricted-methods
          method: 'wallet_addEthereumChain',
          params: [chainConfigBsc.chainParam]
        }).then(()=>{ // null
          // console.log('addSuccess')
          getBscInfo()
        }).catch(addError=>{
          updateLoadingBsc(false)
          if (4001!==addError.code) { // 4001: user reject
            console.error('addError', addError)
          }
        })
      })
      return
    }

    window.ethereum.request({method: 'eth_requestAccounts'}).then(async addresses=>{
      const accountBsc = addresses[0] // pass to child
      // console.log('accountBsc', accountBsc)
      const signer = provider.getSigner()
      const jusdContract = new ethers.Contract(jusdAddress, // pass to child
        [
          'function balanceOf(address) view returns(uint256 balance)',
          'function transfer(address, uint256) public'
        ],
      signer)
      // console.log('jusdContract', jusdContract)
      // get BNB and JUSD balance
      const justBalance = await jusdContract.balanceOf(accountBsc)
      const bnbBalance = await provider.getBalance(accountBsc)
      const bscInfo = { account: accountBsc, balances: {JUSD: justBalance, BNB: bnbBalance}, jusdContract: jusdContract, bscSigner: signer }
      updateBscInfo(bscInfo)
      bscConnected = true
      // console.log('bscInfo', bscInfo)
      updateLoadingBsc(false)

      if (!intervalMetamask) {
        intervalMetamask = setInterval(()=>{ // this api request will not show on Network console
          getBscInfo()
        }, 5000)
      }

    }).catch(error=>{
      console.error('eth_requestAccounts error:', error)
      window.location.reload()
      updateLoadingBsc(false)
    })
  }
  const connectBscClicked = async () => {
    if (!ethereum) {
      alert('Please install Metamask extension'); return
    }
    updateLoadingBsc(true)
    getBscInfo()
  }
  const signoutBscClicked = () => {
    bscConnected = false
    updateBscInfo({ account: undefined, balances: undefined, jusdContract: undefined, bscSigner: undefined })
    clearInterval(intervalMetamask); intervalMetamask=undefined
  }

  const notifyFromSon = (e) => {
    // console.log('notifyFromSon', e)
    switch(e) {
      case 'balance_changed':
        if (joltifyChainInfo.account) {
          getJoltifyInfo()
        }
        if (bscInfo.account) {
          getBscInfo()
        }
      break
      case 'connect_joltify':
        connectClicked()
      break;
      case 'connect_bsc': 
        connectBscClicked()
      break
      default:
      break
    }
  }

  const tabsInfo = [
    {
      title: 'Bridge',
      icon: imageBridge,
      url: 'bridge',
    },
    {
      title: 'Pools',
      icon: imagePools,
      url: 'pools',
    },
    {
      title: 'Staking',
      icon: imgStake,
      url: '',
    }
  ]

  let tabIndexs = {
    '/bridge': 0, '/pools': 1, '/': 2
  }
  const params = useParams()
  tabIndexs[`/pool/${params.poolId}`] = 1
  tabIndexs[`/pool/${params.poolId}/investments`] = 1

  const initTabBg = () => {
    // console.log('initTabBg')
    domMenuTabs = document.getElementsByClassName('menu-tab')
    for (let index = 0; index < domMenuTabs.length; index++) {
      menuTabInfos.push({
        toTop: domMenuTabs[index].getBoundingClientRect().top,
        height: domMenuTabs[index].getBoundingClientRect().height
      })
    }
    domTabBg = document.getElementById('tab-bg')
    tabBgHeight = domTabBg.getBoundingClientRect().height

    // console.log('location', location)
    const indexOfTab = tabIndexs[location.pathname]
    if (!isNaN(indexOfTab)) {
      domTabBg.style.top = menuTabInfos[indexOfTab].toTop + menuTabInfos[indexOfTab].height / 2 - tabBgHeight / 2 + 'px'
    }
    updateTabindex(indexOfTab)
  }

  useEffect(() => {
    // console.log('DidMount')
    const domLogo = document.getElementById('img-logo')
    if (domLogo.getBoundingClientRect().height > 0) { // logo loaded before
      initTabBg()
    }
    domLogo.onload = () => { // wait logo img load so that the tab to top is right value
      initTabBg()
    }
  })

  const tabClicked = (index) => {
    return
    domTabBg.style.top = menuTabInfos[index].toTop + menuTabInfos[index].height / 2 - tabBgHeight / 2 + 'px'
    updateTabindex(index)
    navigate('/'+tabsInfo[index].url)
  }

  return (
    <>
      <div className='left-menu'>
        <div id='logo' style={{ marginTop: "38px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img onClick={()=>window.location.href='./'} id="img-logo" src={imageLogo} alt="" style={{ height: '34px', cursor: 'pointer' }} />
        </div>

        <div id='testnet-icon' style={{
          width: '47px', height: '16px', background: '#CD5143', borderRadius: '12px',
          fontSize: '10px', fontFamily: 'Arial-BoldMT, Arial', color: '#FFFFFF', lineHeight: '11px',
          display: 'testnet'===chainStatus ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center',
          position: 'absolute', top: '74px', left: '107px'
        }}>Testnet</div>

        <img id='tab-bg' src={imageTabBg} alt='' />

        {
          tabsInfo.map((item, index) => {
            return (
              <div style={{ opacity: tabIndex === index ? 1 : 0.4 }} onClick={() => tabClicked(index)} className="menu-tab" key={'tab-' + index}>
                <div>
                  <img style={{ height: '20px', marginRight: '24px' }} src={item.icon} alt='' />
                </div>
                <div className='menu-title'>{item.title}</div>
              </div>
            )
          })
        }

        <div style={{ opacity: '0.4', display: 'mainnet'===chainStatus?'none':'none' }} onClick={() => window.open(stakingUrl)} className="menu-tab">
          <div>
            <img style={{ height: '20px', marginRight: '24px' }} src={imgStaking} alt='' />
          </div>
          <div className='menu-title'>Staking</div>
          <div>
            <img style={{ height: '12px', marginLeft: '6px' }} src={imgLink} alt='' />
          </div>
        </div>

        <div style={{ opacity: '0.4' }} /* onClick={() => window.open(explorerUrl)} */ className="menu-tab">
          <div>
            <img style={{ height: '20px', marginRight: '24px' }} src={imgBigDipper} alt='' />
          </div>
          <div className='menu-title'>Explorer</div>
          <div>
            <img style={{ height: '12px', marginLeft: '6px' }} src={imgLink} alt='' />
          </div>
        </div>

        <div style={{ opacity: '0.4' }} onClick={()=>window.open(termsUrl)} className="menu-tab">
          <div>
            <img style={{ height: '20px', marginRight: '24px' }} src='https://nftstorage.link/ipfs/bafkreidx33dsesbbd77pyrwjsh5qqauh6m4gmlh2cce7hsg42jaaej75zu' alt='' />
          </div>
          <div className='menu-title'>Terms of Use</div>
          <div>
            <img style={{ height: '12px', marginLeft: '6px' }} src={imgLink} alt='' />
          </div>
        </div>

        <div id='wallets' style={{ opacity: '1', position: 'absolute', bottom: '160px', width: '100%', padding: '0 30px', boxSizing: 'border-box' }}>
          <div id='joltify-wallet' style={{ marginBottom: '26px'}}>
            <div style={{ display: joltifyChainInfo.account ? '' : 'none' }} id='balance'>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '60px' }}>
                <img src={imgKeplr2} alt='' height="20px" />
                <div style={{ display: 'flex',  flexDirection: 'column', marginLeft: '12px', marginRight: '25px', width: '100px'}}>
                  <div id="wallet-name">{
                    joltifyChainInfo.account ? (joltifyChainInfo.account.substr(0, '4') + '....' + joltifyChainInfo.account.substr(joltifyChainInfo.account.length - 4, '4')) : 'Joltify Wallet'
                  }</div>
                  <div id="wallet-value" style={{ marginTop: '8px' }}>{
                    joltifyChainInfo.balances ? ethers.utils.formatEther(joltifyChainInfo.balances.JOLT.toString()).toFloor(4) : '0'
                    // '12345.6789'
                  } JOLT</div>
                </div>
                <img onClick={signoutClicked} style={{cursor: 'pointer', opacity: '0.6'}} src={imageSignout} alt='' height="20px" />
              </div>
            </div>

            <div disabled={loading} id='btn-wallet'>
              <div style={{ }}>
                <div onClick={connectClicked} style={{ display: joltifyChainInfo.account ? 'none' : '' }} id='btn-connect'>
                  <img style={{ flexDirection: 'row', marginRight: '13px', height: '20px', marginLeft: '29px' }} src={loading?imgLoading:imgKeplr1} alt='' />
                  <div style={{ flexDirection: 'row', }}>
                    Connect Joltify
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id='bsc-wallet'>
            <div style={{ display: bscInfo.account || false ? '' : 'none' }} id='balance'>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '60px' }}>
                <img src={imgMetamask} alt='' height="20px" />
                <div style={{ display: 'flex',  flexDirection: 'column', marginLeft: '12px', marginRight: '25px', width: '100px'}}>
                <div id="wallet-name">{
                    bscInfo.account ? (bscInfo.account.substr(0, '4') + '....' + bscInfo.account.substr(bscInfo.account.length - 4, '4')) : 'BSC Wallet'
                  }</div>
                  <div id="wallet-value" style={{ marginTop: '8px' }}>{
                    bscInfo.balances ? ethers.utils.formatEther(bscInfo.balances.BNB.toString()).toFloor(4) : '0'
                  } BNB</div>
                </div>
                <img onClick={signoutBscClicked} style={{cursor: 'pointer', opacity: '0.6'}} src={imageSignout} alt='' height="20px" />
              </div>
            </div>

            <div disabled={loadingBsc} id='btn-wallet'>
              <div onClick={connectBscClicked} style={{ display: bscInfo.account || false ? 'none' : '' }} id='btn-connect-bsc'>
                <img style={{ flexDirection: 'row', marginRight: '13px', height: '20px', marginLeft: '29px' }} src={loadingBsc?imgLoading:imgMetamask} alt='' />
                <div style={{ flexDirection: 'row'}}>
                  Connect BSC
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='price-info'>
          Price Data by CoinGecko
        </div>

        <div id="social-icons">
          <div style={{ margin: '0 15px', display: 'flex' }}>
            <div className='social-icon'>
              <img onClick={() => window.open('https://twitter.com/joltify_finance')} className='img-social' src={imageTwitter} style={{ height: "30px" }} alt="" />
            </div>
            <div className='social-icon'>
              <img onClick={() => window.open('https://medium.com/joltify')} className='img-social' src={imageMedium} style={{ height: "30px" }} alt="" />
            </div>
            <div className='social-icon'>
              <img onClick={() => window.open('https://discord.gg/8CRSAvueCF')} className='img-social' src={imageDiscord} style={{ height: "30px" }} alt="" />
            </div>
            <div className='social-icon'>
              <img onClick={() => window.open('https://t.me/joltify_finance')} className='img-social' src={imageTelegram} style={{ height: "30px" }} alt="" />
            </div>
          </div>
        </div>

      </div>

      <div id='content' style={{ marginLeft: '266px' }}>
        <Outlet context={{joltifyChainInfo, bscInfo, notifyFather: notifyFromSon, joltifyConnecting: loading, bscConnecting: loadingBsc, setOutletPassback, provider, currentChainId}} />
      </div>
    </>
  )
}
export default Home