import {useState, useEffect} from 'react'
import imgSeeToken from '@/assets/See_Token_Info.svg'
import imgAddToMetamask from '@/assets/Add_to_Metamask.svg'
import imgDeposit from '@/assets/Deposit.svg'
import imgWithdraw from '@/assets/Withdraw.svg'
import imgJolt from '@/assets/JOLT-Stake-icon.svg'
import imgJoltBnb from '@/assets/JOLT-BNB.svg'
import imgMetamask from '@/assets/metamask.svg'
import imgClose from '@/assets/icon-close.svg'
import imgJoltToken from '@/assets/token-jolt.svg'
import imgLink from '@/assets/icon-link-deco.svg'
import imgLoadingWhite from '@/assets/loading-white.gif'
import imgBanner from '@/assets/banner-staking.png'
import {useOutletContext} from 'react-router-dom'
import {stakingConfigBsc, chainConfigBsc} from '../../configs/index'
import Decimal from 'decimal.js'

const ethers = require('ethers')
let interval = undefined // this will not reset when rerend
const chainStatus = process.env.REACT_APP_CHAIN_STATUS
let aprGot = false
let oldJoltInfo = {price: 0, totalStaked: 0, apr: 0, lockDuration: 0, joltPrice: 0, feePercent: 0}
let oldJoltBnbInfo = {price: 0, totalStaked: 0, apr: 0, lockDuration: 0, joltPrice: 0, feePercent: 0}
const defaultUserTokenInfo = {balance: 0, allowance: 0, staked:0, stakedJolt:0, depositDate: 0, emission: 0, deltTime: 0} // stakedJolt only used in JOLT-BNB
let oldUserJoltInfo = defaultUserTokenInfo
let oldUserJoltBnbInfo = defaultUserTokenInfo
let currentChainId
let props
let deltTimes = {'JOLT': 0, 'JOLT-BNB': 0}
let countdownInterval

function Staking() {
  const [joltInfo, setJoltInfo] = useState(oldJoltInfo)
  const [joltBnbInfo, setJoltBnbInfo] = useState(oldJoltBnbInfo)
  const [userJoltInfo, setUserJoltInfo] = useState(oldUserJoltInfo)
  const [userJoltBnbInfo, setUserJoltBnbInfo] = useState(oldUserJoltBnbInfo)
  const [countdownLabelJolt, setCountdownLebelJolt] = useState('')
  const [countdownLabelLP, setCountdownLebelLP] = useState('')
  const [isMaxChecked, setIsMaxChecked] = useState(false)

  const [openDialog, setOpenDialog] = useState(false)
  const [dialogType, setDialogType] = useState({side: 'DEPOSIT', token: 'JOLT'})
  props = useOutletContext()
  currentChainId = props.currentChainId
  // console.log('props:', props)
  let provider = props.provider
  if (!provider) {
    provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/')
  }
  const signer = props.bscInfo.bscSigner

  function changeTokenInfo(tokenType, key, value) {
    let tokenInfo
    switch(tokenType) {
      case 'JOLT-User': 
        tokenInfo = JSON.parse(JSON.stringify(oldUserJoltInfo))
        tokenInfo[key] = value
        oldUserJoltInfo = tokenInfo
        setUserJoltInfo(tokenInfo)
        break
      case 'JOLT-BNB-User':
        tokenInfo = JSON.parse(JSON.stringify(oldUserJoltBnbInfo))
        tokenInfo[key] = value
        oldUserJoltBnbInfo = tokenInfo
        setUserJoltBnbInfo(oldUserJoltBnbInfo)
        break
      case 'JOLT':
        tokenInfo = JSON.parse(JSON.stringify(oldJoltInfo))
        tokenInfo[key] = value
        oldJoltInfo = tokenInfo
        setJoltInfo(tokenInfo)
        break
      case 'JOLT-BNB':
        tokenInfo = JSON.parse(JSON.stringify(oldJoltBnbInfo))
        tokenInfo[key] = value
        oldJoltBnbInfo = tokenInfo
        setJoltBnbInfo(tokenInfo)
        break
    }
  }

  const abis = [
    'function totalStaked() view returns(uint256)',
    'function basicAPR() view returns(uint256)',
    'function withdrawalLockDuration() view returns(uint256)',
    'function depositDates(address) view returns(uint256)',
    'function balances(address) view returns(uint256)',
    'function getAccruedEmission(uint256 depositDate, uint256 amount) view returns(uint256 total, uint256 userShare, uint256 timePassed)',
    'function forcedWithdrawalFee() view returns(uint256)'
  ]
  const stakingContract = new ethers.Contract(stakingConfigBsc.stakingAddress, abis, provider)
  const stakingLPContract = new ethers.Contract(stakingConfigBsc.stakingAddressLP, [...abis, 'function balancesReward(address) view returns(uint256)'], provider)
  
  const abis2 = [
    'function balanceOf(address) view returns(uint256)',
    'function allowance(address owner, address spender) view returns(uint256)'
  ]
  const joltContract = new ethers.Contract(stakingConfigBsc.joltAddress, abis2, provider)
  const joltBnbContract = new ethers.Contract(stakingConfigBsc.joltBnbAddress, abis2, provider)

  // get APR(JOLT)、lockDuration、 here, only need get once
  async function getAprs() {
    if (!aprGot && 0!==joltInfo.price && 0!==joltBnbInfo.price) {
      changeTokenInfo('JOLT', 'apr',     (new Decimal(ethers.utils.formatEther(await stakingContract.basicAPR()  )).mul(100)).toString())
      changeTokenInfo('JOLT', 'lockDuration', (await stakingContract.withdrawalLockDuration()).toString() )
      changeTokenInfo('JOLT-BNB', 'lockDuration', (await stakingLPContract.withdrawalLockDuration()).toString() )
      aprGot = true
    }
  }
  getAprs()

  let busdBnb, joltBnb
  if ('mainnet'===chainStatus) { // only mainnet can pair token
    busdBnb = new ethers.Contract(stakingConfigBsc.busdBnbAddress, [
      'function getReserves() view returns(uint112, uint112, uint32)'
    ], provider)
    joltBnb = new ethers.Contract(stakingConfigBsc.joltBnbAddress, [
      'function getReserves() view returns(uint112, uint112, uint32)',
      'function totalSupply() view returns(uint256)'
    ], provider)
  } else {
    changeTokenInfo('JOLT', 'price', 1)
    changeTokenInfo('JOLT-BNB', 'price', 100)
  }

  if (window.ethereum) {
    window.ethereum.on('connect', connectInfo=>{
      getChainInfo()
    })
  }

  useEffect(()=>{
    getChainInfo()
    setBscConnecting(props.bscConnecting)
    // console.log('useEffect', props.currentChainId)
  }, [props])

  async function getChainInfo() {
    // console.log(currentChainId, chainConfigBsc.chainParam.chainId)
    let chainId = 0
    if (window.ethereum) {
      chainId = window.ethereum.chainId
    } else {
      chainId = (await provider.getNetwork()).chainId
    }
    if (parseInt(chainId)!==parseInt(chainConfigBsc.chainParam.chainId) || !provider) {
      // console.log('currentChainId', window.ethereum.chainId)
      // console.log('chainConfigBsc.chainParam.chainId', chainConfigBsc.chainParam.chainId)
      // console.log('provider', provider.getChainId())
      // console.log( 'window.ethereum', window.ethereum.chainId )
      return
    }
    // get info from pancake
    if (busdBnb && joltBnb) { // if testnet, this will be false
      const busdBnbReserves = await busdBnb.getReserves()
      const bnbValuesOfBusdBN = (new Decimal(ethers.utils.formatEther(busdBnbReserves[1]))).div(new Decimal(ethers.utils.formatEther(busdBnbReserves[0]))) // 0: BNB < 1: BUSD
      const joltBnbReserves = await joltBnb.getReserves()
      const joltValuesOfBnbBN = (new Decimal(ethers.utils.formatEther(joltBnbReserves[1]))).div(new Decimal(ethers.utils.formatEther(joltBnbReserves[0]))) // 0: JOLT > 1: BNB
      const joltBnbSupply = await joltBnb.totalSupply()
      const joltBnbValuesOfBnbBN = (new Decimal(ethers.utils.formatEther(joltBnbReserves[1]))).mul(2).div(new Decimal(ethers.utils.formatEther(joltBnbSupply)))
      const joltPrice = (joltValuesOfBnbBN.mul(bnbValuesOfBusdBN)).toString()
      changeTokenInfo('JOLT', 'price', joltPrice)
      changeTokenInfo('JOLT', 'joltPrice', joltPrice)
      changeTokenInfo('JOLT-BNB', 'joltPrice', joltPrice)
      changeTokenInfo('JOLT-BNB', 'price', (joltBnbValuesOfBnbBN.mul(bnbValuesOfBusdBN)).toString())
      const lpJoltRatio = (new Decimal( ethers.utils.formatEther(joltBnbSupply) ).div( ethers.utils.formatEther(joltBnbReserves[1]) )).toString()
      // console.log('lpJoltRatio:', lpJoltRatio)
      changeTokenInfo('JOLT-BNB', 'apr', (new Decimal(ethers.utils.formatEther(await stakingLPContract.basicAPR())).mul(100).div(2).div(lpJoltRatio)).toString() )
    }
    // get JOLT and JOLT-BNB total staked and calculate the values
    changeTokenInfo('JOLT', 'totalStaked', ethers.utils.formatEther(await stakingContract.totalStaked()).toString() )
    changeTokenInfo('JOLT-BNB', 'totalStaked', ethers.utils.formatEther(await stakingLPContract.totalStaked()).toString() )
    
    // get withdrawal fee
    changeTokenInfo('JOLT', 'feePercent', ethers.utils.formatEther(await stakingContract.forcedWithdrawalFee()).toString() )
    changeTokenInfo('JOLT-BNB', 'feePercent', ethers.utils.formatEther(await stakingLPContract.forcedWithdrawalFee()).toString() )

    // get user's info from block
    const account = props.bscInfo.account
    // console.log('props.bscInfo', props.bscInfo)
    if (account) {
      // balance: JOLT or JOLT-BNB balance
      changeTokenInfo('JOLT-User', 'balance', (ethers.utils.formatEther(await joltContract.balanceOf(account))).toString() )
      changeTokenInfo('JOLT-BNB-User', 'balance', (ethers.utils.formatEther(await joltBnbContract.balanceOf(account))).toString() )
      // allowance: with Staking contract
      const allowanceToJoltStaling = (ethers.utils.formatEther(await joltContract.allowance(account, stakingConfigBsc.stakingAddress))).toString()
      // console.log('allowanceToJoltStaling', allowanceToJoltStaling, Number(allowanceToJoltStaling))
      changeTokenInfo('JOLT-User', 'allowance', allowanceToJoltStaling )
      changeTokenInfo('JOLT-BNB-User', 'allowance', (ethers.utils.formatEther(await joltBnbContract.allowance(account, stakingConfigBsc.stakingAddressLP))).toString() )
      // stakedJolt: on LP staking contract, 
      changeTokenInfo('JOLT-BNB-User', 'stakedJolt', (ethers.utils.formatEther(await stakingLPContract.balancesReward(account))).toString() )
      // staked: on staking contract, 
      let stakedJoltStaking = '0'
      stakedJoltStaking = (ethers.utils.formatEther(await stakingContract.balances(account))).toString()
      changeTokenInfo('JOLT-User', 'staked', stakedJoltStaking)
      const stakedLPStaking = (ethers.utils.formatEther(await stakingLPContract.balances(account))).toString()
      changeTokenInfo('JOLT-BNB-User', 'staked', stakedLPStaking)
      // depositDate: 
      const depositDateJolt = (await stakingContract.depositDates(account)).toString()
      changeTokenInfo('JOLT-User', 'depositDate', depositDateJolt)
      const depositDateLP = (await stakingLPContract.depositDates(account)).toString()
      changeTokenInfo('JOLT-BNB-User', 'depositDate', depositDateLP)
      // emission: need staked and depositDate
      // console.log( await stakingContract.getAccruedEmission( depositDateJolt, ethers.utils.parseEther(stakedJoltStaking) ) )
      changeTokenInfo('JOLT-User', 'emission', (ethers.utils.formatEther((await stakingContract.getAccruedEmission(depositDateJolt, ethers.utils.parseEther(stakedJoltStaking))).userShare)).toString() )
      changeTokenInfo('JOLT-BNB-User', 'emission', (ethers.utils.formatEther((await stakingLPContract.getAccruedEmission(depositDateLP, ethers.utils.parseEther(stakedLPStaking))).userShare)).toString() )
    } else {
      setUserJoltInfo(defaultUserTokenInfo)
      setUserJoltBnbInfo(defaultUserTokenInfo)
      oldUserJoltInfo = defaultUserTokenInfo
      oldUserJoltBnbInfo = defaultUserTokenInfo
    }
  }
  if (undefined === interval) {
    interval =setInterval(async ()=>{ // getting info from blockchain every 4 second
      getChainInfo()
    }, 4000)
  }

  // calculate count down every 0.5 s
  if (undefined===countdownInterval) {
    countdownInterval = setInterval(()=>{
      const deltTimeJolt = Number(oldUserJoltInfo.depositDate) + Number(oldJoltInfo.lockDuration) - (new Date().getTime())/1000
      changeTokenInfo('JOLT-User', 'deltTime', deltTimeJolt)
      // console.log(`${oldUserJoltInfo.depositDate} + ${oldJoltInfo.lockDuration} - ${(new Date().getTime())/1000} = ${deltTimeJolt}`)
      if (deltTimeJolt>0) {
        const hour = parseInt(deltTimeJolt / 3600)
        const minute = parseInt((deltTimeJolt - hour*3600) / 60)
        const second = parseInt(deltTimeJolt % 60)
        // console.log(`${hour}h:${minute}m:${second}s`)
        setCountdownLebelJolt(`${hour}h:${minute}m:${second}s`)
      } else {
        setCountdownLebelJolt('')
      }
      const deltTimeLP = Number(oldUserJoltBnbInfo.depositDate) + Number(oldJoltBnbInfo.lockDuration) - (new Date().getTime())/1000
      changeTokenInfo('JOLT-BNB-User', 'deltTime', deltTimeLP)
      if (deltTimeLP>0) {
        const hour = parseInt(deltTimeLP / 3600)
        const minute = parseInt((deltTimeLP - hour*3600) / 60)
        const second = parseInt(deltTimeLP % 60)
        // console.log(`${hour}h:${minute}m:${second}s`)
        setCountdownLebelLP(`${hour}h:${minute}m:${second}s`)
      } else {
        setCountdownLebelLP('')
      }
    }, 500)
  }

  // prepare signer for enable deposit and withdrawal
  let joltContractSigned
  let lpContractSigned
  const abisForTokenSigner = [
    'function approve(address spender, uint256 amount) public returns(bool)',
  ]
  const abisForStakingSigner = [
    'function deposit(uint256 _amount) public',
    'function withdraw(uint256 _amount) public',
  ]
  let joltStakingContractSigner
  let lpStakingContractSigner
  if (props.bscInfo.bscSigner) {
    joltContractSigned = new ethers.Contract(stakingConfigBsc.joltAddress, abisForTokenSigner, props.bscInfo.bscSigner)
    lpContractSigned = new ethers.Contract(stakingConfigBsc.joltBnbAddress, abisForTokenSigner, props.bscInfo.bscSigner)
    joltStakingContractSigner = new ethers.Contract(stakingConfigBsc.stakingAddress, abisForStakingSigner, props.bscInfo.bscSigner)
    lpStakingContractSigner = new ethers.Contract(stakingConfigBsc.stakingAddressLP, abisForStakingSigner, props.bscInfo.bscSigner)
  }

  const [popBtnBusy, setPopBtnBusy] = useState(false)
  async function popBtnClicked() {
    let inputVal = document.getElementById('input').value
    // console.log('inputVal', inputVal)
    // console.log('dialogType.side', dialogType.side)
    // console.log('dialogType.token', dialogType.token)
    if ( NaN===Number(inputVal) || Number(inputVal)<=0 ) {
      alert('Please input a positive number')
      return
    }
    const amount = ethers.utils.parseEther(inputVal)
    setPopBtnBusy(true)
    if ('DEPOSIT'===dialogType.side) {
      if ('JOLT'===dialogType.token) {
        try {
          const tx = await joltStakingContractSigner.deposit(amount)
          tx.wait().finally(()=>{
            setPopBtnBusy(false)
            setOpenDialog(false)
          })
        } catch(e) {
          console.log('error', e)
          setPopBtnBusy(false)
        }
      } else if ('JOLT-BNB'===dialogType.token) {
        try {
          const tx = await lpStakingContractSigner.deposit(amount)
          tx.wait().finally(()=>{
            setPopBtnBusy(false)
            setOpenDialog(false)
          })
        } catch(e) {
          console.log('error', e)
          setPopBtnBusy(false)
        }
      } else {
        setPopBtnBusy(false)
      }
    } else if ('WITHDRAW'===dialogType.side) {
      if ('JOLT'===dialogType.token) {
        try {
          let withdrawAmount = isMaxChecked?ethers.BigNumber.from('0'):amount
          console.log('withdrawAmount', withdrawAmount.toString(), withdrawAmount)
          // withdrawAmount = ethers.BigNumber.from('1000000000000000000000000') // debug
          const tx = await joltStakingContractSigner.withdraw(withdrawAmount)
          tx.wait().finally(()=>{
            setPopBtnBusy(false)
            setOpenDialog(false)
          })
        } catch(e) {
          console.log('error', e)
          alert(`❌ error, ${e.data?.message}. ${JSON.stringify(e)}`)
          // alert(`Error, your input is ${amount} Wei`)
          setPopBtnBusy(false)
        }
      } else if ('JOLT-BNB'===dialogType.token) {
        try {
          const tx = await lpStakingContractSigner.withdraw(amount)
          tx.wait().finally(()=>{
            setPopBtnBusy(false)
            setOpenDialog(false)
          })
        } catch(e) {
          console.log('error', e)
          setPopBtnBusy(false)
        }
      } else {
        alert('Token is not JOLT or JOLT-BNB')
        setPopBtnBusy(false)
      }
    } else {
      alert('Execustion is not Depost or Withdraw')
      setPopBtnBusy(false)
    }
  }

  const [joltEnableBtnLoading, setJoltEnableBtnLoading] = useState(false)
  const [lpEnableBtnLoading, setLpEnableBtnLoading] = useState(false)
  async function enableClicked(tokenName) {
    if (!props.bscInfo.bscSigner) {
      return
    }
    if ('JOLT'===tokenName) {
      // set JOLT enable btn loading
      setJoltEnableBtnLoading(true)
      try {
        const tx = await joltContractSigned.approve(stakingConfigBsc.stakingAddress, ethers.BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'))
        tx.wait().finally(_=>{
          // remove JOLT enable btn loading
          setJoltEnableBtnLoading(false)
        })
      } catch(e) {
        console.error('enable to JOLT staking error:', stakingConfigBsc.stakingAddress, e)
        setJoltEnableBtnLoading(false)
      }
    } else if ('JOLT-BNB'===tokenName) {
      // set LP enable btn loading
      setLpEnableBtnLoading(true)
      try {
        const tx = await lpContractSigned.approve(stakingConfigBsc.stakingAddressLP, ethers.BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'))
        tx.wait().finally(_=>{
          // remove LP enable btn loading
          setLpEnableBtnLoading(false)
        })
      } catch(e) {
        console.error('enable to LP staking error:', stakingConfigBsc.stakingAddressLP, e)
        setLpEnableBtnLoading(false)
      }
    }
  }

  useEffect(()=>{
    document.body.style.overflow = 'auto'
    if (openDialog) {
      document.body.style.overflow = 'hidden'
    }
    document.getElementById('input').value = ''
    setPercentBtnIndex(0)
    setPopEarned( '' )
    setPopFee( '' )
    setTotal( '' )
  }, [openDialog])

  const [percentBtnIndex, setPercentBtnIndex] = useState(0) // 0: not selected, 1: 25%, 2: 50%, 3: 75%, 4: MAX
  function percentBtnClicked(index) {
    setIsMaxChecked(false)
    setPercentBtnIndex(index)
    let inputVal = ''
    if ('DEPOSIT'===dialogType.side) {
      if ('JOLT'===dialogType.token) {
        if (4===index) {
          inputVal = new Decimal(userJoltInfo.balance)
        } else {
          inputVal = (new Decimal(userJoltInfo.balance).mul(0.25*index)).toFixed(4)
        }
      } else if ('JOLT-BNB'===dialogType.token) {
        if (4===index) {
          inputVal = new Decimal(userJoltBnbInfo.balance)
        } else {
          inputVal = (new Decimal(userJoltBnbInfo.balance).mul(0.25*index)).toFixed(4)
        }
      }
    } else if ('WITHDRAW'===dialogType.side) {
      if ('JOLT'===dialogType.token) {
        if (4===index) {
          console.log('Max Clicked')
          setIsMaxChecked(true)
          inputVal = new Decimal(userJoltInfo.staked)
        } else {
          inputVal = (new Decimal(userJoltInfo.staked).mul(0.25*index)).toFixed(4)
        }
      } else if ('JOLT-BNB'===dialogType.token) {
        if (4===index) {
          inputVal = new Decimal(userJoltBnbInfo.staked)
        } else {
          inputVal = (new Decimal(userJoltBnbInfo.staked).mul(0.25*index)).toFixed(4)
        }
      }
    }
    document.getElementById('input').value = inputVal
    calculateFeeAndEmission(inputVal)
  }

  const [popEarned, setPopEarned] = useState(0)
  const [popFee, setPopFee] = useState(0)
  const [popTotal, setTotal] = useState(0)
  function calculateFeeAndEmission(inputVal) {
    if ('WITHDRAW'!==dialogType.side) {
      return
    }
    if ('JOLT'===dialogType.token) {
      if ( new Decimal(inputVal).gt( userJoltInfo.staked ) ) {
        setPopEarned( '' )
        setPopFee( '' )
        setTotal( '' )
        return
      }
      const earned = new Decimal(userJoltInfo.emission).mul( new Decimal(inputVal) ).div(userJoltInfo.staked)
      let fee = new Decimal(0)
      let totalOut = new Decimal(inputVal).add(earned)
      if (userJoltInfo.deltTime>0) {
        fee = totalOut.mul( joltInfo.feePercent )
        totalOut = totalOut.sub(fee)
      }
      setPopEarned( `${earned.toFixed(4)} JOLTs` )
      setPopFee( `-${fee.toFixed(4)} JOLTs` )
      setTotal( `${totalOut.toFixed(4)} JOLTs` )
    } else if ('JOLT-BNB'===dialogType.token) {
      if ( new Decimal(inputVal).gt( userJoltBnbInfo.staked ) ) {
        setPopEarned( '' )
        setPopFee( '' )
        setTotal( '' )
        return
      }
      let earnJolt = new Decimal(userJoltBnbInfo.stakedJolt).add( new Decimal(userJoltBnbInfo.emission).mul( new Decimal(inputVal) ).div(userJoltBnbInfo.staked) )
      let outJolt = earnJolt.toFixed(4)
      let outLp = new Decimal(inputVal)
      let feeJolt = new Decimal(0)
      let feeLp = new Decimal(0)
      if (userJoltBnbInfo.deltTime>0) {
        feeJolt = (earnJolt.mul(joltBnbInfo.feePercent)).toFixed(4)
        feeLp = (outLp.mul(joltBnbInfo.feePercent)).toFixed(4)
        outJolt = (earnJolt.sub(feeJolt)).toFixed(4)
        outLp = (outLp.sub(feeLp)).toFixed(4)
      }
      setPopEarned( `${earnJolt.toFixed(4)} JOLTs` )
      setPopFee( `-${feeJolt} JOLTs, -${feeLp} JOLT-BNBs` )
      setTotal( `${outJolt} JOLTs, ${outLp} JOLT-BNBs` )
    }
  }
  function inputChanged(e) {
    setIsMaxChecked(false)
    setPercentBtnIndex(0)
    let inputVal = e.currentTarget.value
    if ( NaN===Number(inputVal) || Number(inputVal)<=0 ) {
      inputVal = 0
    }
    calculateFeeAndEmission(inputVal)
  }

  function seeTokenInfoClicked(tokenType) {
    if ('JOLT'===tokenType) {
      window.open( `${chainConfigBsc.chainParam.blockExplorerUrls[0]}/address/${stakingConfigBsc.joltAddress}` )
    } else if ('JOLT-BNB'===tokenType) {
      window.open( `${chainConfigBsc.chainParam.blockExplorerUrls[0]}/address/${stakingConfigBsc.joltBnbAddress}` )
    }
  }

  function addToMetamaskClicked(tokenType) {
    // console.log('addToMetamaskClicked', tokenType)
    if (!window.ethereum) {
      return
    }
    let contractAddress = stakingConfigBsc.joltAddress
    let icon = window.location.protocol+"//"+window.location.host+'/'+imgJoltToken
    if ('JOLT-BNB'===tokenType) {
      contractAddress = stakingConfigBsc.joltBnbAddress
      icon = window.location.protocol+"//"+window.location.host+'/'+imgJoltBnb
    }
    window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: contractAddress,
          symbol: tokenType,
          decimals: 18,
          image: icon
        },
      }
    })
  }

  const [bscConnecting, setBscConnecting] = useState(false)
  
  return (
    <div style={{maxWidth: '1080px', width: '100%', margin: '0 auto', color: '#FFFFFF'}}>
      {/* <img src={imgBanner} style={{width: '100%', marginBottom: '23px'}} alt="" />
      <div style={{fontSize: '50px', fontFamily: 'Arial-BoldMT, Arial', lineHeight: '58px', marginTop: '80px'}}>JOLT STAKING</div>
      <div style={{fontSize: '20px', fontFamily: 'ArialMT', color: '#9FA8AB', lineHeight: '23px', marginTop: '13px'}}>Just stake some tokens to earn. High APR, low risk</div> */}
      
      <img src={imgBanner} style={{width: '100%', marginBottom: '23px', marginTop: '51px', pointerEvents: 'none'}} alt="" />
      <StakCard notifyFather={props.notifyFather} setBscConnecting={setBscConnecting} bscConnecting={bscConnecting} addToMetamaskClicked={addToMetamaskClicked} seeTokenInfoClicked={seeTokenInfoClicked} enabling={joltEnableBtnLoading} tokenName="JOLT" setOpenDialog={setOpenDialog} setDialogType={setDialogType} enableClicked={enableClicked} account={props.bscInfo.account} tokenInfo={joltInfo} userTokenInfo={userJoltInfo} countdownLabel={countdownLabelJolt} />
      <StakCard notifyFather={props.notifyFather} setBscConnecting={setBscConnecting} bscConnecting={bscConnecting} addToMetamaskClicked={addToMetamaskClicked} seeTokenInfoClicked={seeTokenInfoClicked} enabling={lpEnableBtnLoading} tokenName="JOLT-BNB" setOpenDialog={setOpenDialog} setDialogType={setDialogType} enableClicked={enableClicked} account={props.bscInfo.account} style={{margin: '20px 0 30px'}} tokenInfo={joltBnbInfo} userTokenInfo={userJoltBnbInfo} countdownLabel={countdownLabelLP} />
      
      <div id='staking-dialog' style={{display: openDialog?'flex':'none', alignItems: 'center', justifyContent: 'center', position: 'fixed', zIndex: '65535', background: 'rgba(32, 32, 32, 0.7)', height: '100vh', width: '100%', top: '0', left: 0}}>
        <div style={{borderRadius: '8px', width: '500px', background: '#273C4B', border: '1px solid #455961', padding: '30px 0'}}>
          <div style={{display: 'flex', alignItems: 'center', margin: '0 20px'}}>
            <div style={{width: '34px'}}> </div>
            <div style={{flexGrow: 1, textAlign: 'center', fontSize: '28px', fontFamily: 'Arial-BoldMT, Arial', lineHeight: '32px'}}>JOLTIFY STAKING</div>
            <div onClick={()=>setOpenDialog(false)} style={{width: '14px', height: '14px', backgroundImage: `url(${imgClose})`, cursor: 'pointer', marginRight: '20px'}}></div>
          </div>

          <div style={{height: '2px', backgroundColor: 'rgba(69, 89, 97, 0.24)', marginTop: '26px'}}></div>

          <div style={{margin: '0 20px', boxSizing: 'border-box'}}>
            <div style={{display: 'flex', alignItems: 'center', marginTop: '22px'}}>
              <div style={{flexGrow: 1, fontSize: '20px', fontFamily: 'Arial-BoldMT, Arial', color: '#9FA8AB', lineHeight: '23px'}}>{dialogType.side}:</div>
              <div style={{width: '30px', height: '30px', backgroundImage: `url(${'JOLT'===dialogType.token?imgJoltToken:imgJoltBnb})`, backgroundSize:'100% 100%', marginRight: '10px'}}></div>
              <div style={{fontSize: '16px', fontFamily: 'Arial-BoldMT, Arial'}}>{dialogType.token}</div>
            </div>

            <div style={{marginTop: '22px'}}>
              <input id='input' onChange={(e)=>inputChanged(e)} onInput={(e)=>{e.currentTarget.value=e.currentTarget.value.replace(/[^0-9.]/g,'')}} style={{boxSizing: 'border-box', width: '100%', height: '60px', outline: 'none', borderRadius: '10px', border: '1px solid rgba(255, 255, 255, 0.2)', backgroundColor: 'transparent', fontSize: '28px', color: '#ffffff', fontFamily: 'Arial-BoldMT, Arial', lineHeight: '32px', padding: '0 18px'}} />
            </div>

            <div style={{display: 'DEPOSIT'===dialogType.side?'':'none', marginTop: '11px', textAlign: 'right', fontSize:'18px', fontFamily: 'ArialMT', lineHeight: '21px'}}>
              Balance: {'JOLT'===dialogType.token?( new Decimal(userJoltInfo.balance).toFixed(4) ):( new Decimal(userJoltBnbInfo.balance).toFixed(4) )} {dialogType.token}
            </div>

            <div style={{display: 'DEPOSIT'===dialogType.side?'none':'', marginTop: '11px', textAlign: 'right', fontSize:'18px', fontFamily: 'ArialMT', lineHeight: '21px'}}>
              Staked: {'JOLT'===dialogType.token?( new Decimal(userJoltInfo.staked).toFixed(4) ):( new Decimal(userJoltBnbInfo.staked).toFixed(4) )} {dialogType.token}
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '27px', userSelect: 'none'}}>
              <div onClick={()=>percentBtnClicked(1)} style={{width: '80px', height: '30px', color: 1===percentBtnIndex?'#213340':'#ffffff', background: 1===percentBtnIndex?'#35F3ED':'#284D5B', cursor: 'pointer', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '14px', fontFamily: 'Arial-BoldMT, Arial'}}>25%</div>
              <div onClick={()=>percentBtnClicked(2)} style={{width: '80px', height: '30px', color: 2===percentBtnIndex?'#213340':'#ffffff', background: 2===percentBtnIndex?'#35F3ED':'#284D5B', cursor: 'pointer', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '14px', fontFamily: 'Arial-BoldMT, Arial'}}>50%</div>
              <div onClick={()=>percentBtnClicked(3)} style={{width: '80px', height: '30px', color: 3===percentBtnIndex?'#213340':'#ffffff', background: 3===percentBtnIndex?'#35F3ED':'#284D5B', cursor: 'pointer', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '14px', fontFamily: 'Arial-BoldMT, Arial'}}>75%</div>
              <div onClick={()=>percentBtnClicked(4)} style={{width: '80px', height: '30px', color: 4===percentBtnIndex?'#213340':'#ffffff', background: 4===percentBtnIndex?'#35F3ED':'#284D5B', cursor: 'pointer', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '14px', fontFamily: 'Arial-BoldMT, Arial'}}>MAX</div>
            </div>

            <div id='owner-show-in-withdraw-dialog' style={{display: 'DEPOSIT'===dialogType.side?'none':''}}>
              <div style={{height: '2px', backgroundColor: 'rgba(69, 89, 97, 0.24)', marginTop: '21px'}}></div>
              <div style={{marginTop: '22px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px', color: '#9FA8AB'}}>
                <div style={{fontFamily: 'ArialMT'}}>INTEREST EARNED:</div>
                <div style={{textAlign: 'right', fontFamily: 'Arial-BoldMT, Arial'}}>{popEarned}</div>
              </div>
              <div style={{marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px', color: '#9FA8AB'}}>
                <div style={{fontFamily: 'ArialMT'}}>WITHDRAWAL FEE:</div>
                <div style={{textAlign: 'right', fontFamily: 'Arial-BoldMT, Arial'}}>{popFee}</div>
              </div>
              <div style={{marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px'}}>
                <div style={{fontFamily: 'ArialMT'}}>TOTAL CASHOUT:</div>
                <div style={{textAlign: 'right', fontFamily: 'Arial-BoldMT, Arial'}}>{popTotal}</div>
              </div>
            </div>

            {/* <div style={{color: '#aaaaaa', textAlign: 'center', marginTop: '20px'}}>
              Submit issues? <a href="/withdraw-all-staking.pdf" target='_blank'
                style={{color: '#aaaaaa'}}
              > <b><u>Click here</u></b></a>
            </div> */}

            <div onClick={()=>popBtnClicked()} disabled={popBtnBusy} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '40px', width: '100%', height: '50px', background: 'linear-gradient(233deg, #2AF940 0%, #32FDEF 100%)', borderRadius: '10px', fontSize: '16px', fontFamily: 'PingFangSC-Semibold, PingFang SC', fontWeight: '600', color: '#213340', cursor: 'pointer', userSelect: 'none'}}>
              <div style={{display: popBtnBusy?'':'none', marginRight: '10px', height: '20px', width: '20px', backgroundImage: `url(${imgLoadingWhite})`, backgroundSize: 'cover'}}></div>
              <div>{dialogType.side}</div>
            </div>

            <div onClick={()=>window.open('https://www.mexc.com/zh-CN/exchange/JOLT_USDT')} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px', cursor: 'pointer', userSelect: 'none'}}>
              <div style={{textAlign: 'right', marginRight: '10px', fontSize: '16px', fontFamily: 'Arial-BoldMT, Arial', color: '#32FDEA'}}>GET JOLT</div>
              <div style={{width: '12px', height: '12px', backgroundImage: `url(${imgLink})`}}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

let countdownLabels = {'JOLT': undefined, 'JOLT-BNB': undefined}

function StakCard(props) {
  // console.log(props.userTokenInfo)

  countdownLabels[props.tokenName] = props.countdownLabel

  let style = Object.assign(props.style?props.style:{}, 
    {width: '100%', background: '#213340', borderRadius: '10px', boxSizing: 'border-box', padding: '45px 20px 22px 20px'}
  )
  let tokenImg = imgJolt
  if ('JOLT-BNB'===props.tokenName) {
    tokenImg = imgJoltBnb
  }

  const depositClick = () => {
    props.setDialogType({side: 'DEPOSIT', token: props.tokenName})
    props.setOpenDialog(true)
  }

  const withdrawClick = () =>{
    props.setDialogType({side: 'WITHDRAW', token: props.tokenName})
    props.setOpenDialog(true)
  }

  function enableClicked() {
    props.enableClicked(props.tokenName)
  }

  let profit = props.userTokenInfo.emission
  if ('JOLT-BNB'===props.tokenName) {
    profit = (new Decimal(profit).add(props.userTokenInfo.stakedJolt)).toString()
  }

  return (
    <div style={style}>
      <div id="staking-card-head" style={{display: 'flex', justifyContent: 'space-between'}}>

        <div style={{width: '50px', height: '50px', marginLeft: '20px', marginRight: '24px', background: `url(${tokenImg})`}}></div>

        <div style={{flexGrow: '1'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{fontSize: '22px', fontFamily: 'Arial-BoldMT, Arial', lineHeight: '26px'}}>{props.tokenName}</div>
            <div style={{padding: '0 8px', height: '23px', background: 'rgba(53, 243, 237, 0.1)', borderRadius: '4px', border: '1px solid #35F3ED', boxSizing: 'border-box',
              fontSize: '12px', fontFamily: 'ArialMT', color: '#35F3ED', lineHeight: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '10px'}}>
              APR: {(new Decimal(props.tokenInfo.apr)).toFixed(2)}%
            </div>
          </div>
          <div style={{fontSize: '14px', fontFamily: 'ArialMT', color: '#9FA8AB', lineHeight: '16px', marginTop: '10px'}}>Stake {props.tokenName}</div>
        </div>

        <div style={{width: '2px', height: '58px', background: '#293C43'}}></div>

        <div style={{flexGrow: '1', textAlign: 'center'}}>
          <div style={{fontSize: '22px', fontFamily: 'Arial-BoldMT, Arial', lineHeight: '26px'}}>{new Decimal(props.tokenInfo.totalStaked).toFixed(4)} {props.tokenName}</div>
          <div style={{fontSize: '12px', fontFamily: 'ArialMT', color: '#9FA8AB', lineHeight: '14px', marginTop: '17px'}}>Total staked</div>
        </div>

        <div style={{width: '2px', height: '58px', background: '#293C43'}}></div>

        <div style={{flexGrow: '1', textAlign: 'center'}}>
          <div style={{fontSize: '22px', fontFamily: 'Arial-BoldMT, Arial', lineHeight: '26px'}}>${(new Decimal(props.tokenInfo.totalStaked).mul(new Decimal(props.tokenInfo.price)).toFixed(4))}</div>
          <div style={{fontSize: '12px', fontFamily: 'ArialMT', color: '#9FA8AB', lineHeight: '14px', marginTop: '17px'}}>TOTAL LOCKED VALUE (1{props.tokenName} = ${(new Decimal(props.tokenInfo.price).toFixed(4))})</div>
        </div>

      </div>

      <div id="staking-card-info" style={{display: 'flex', marginTop: '39px'}}>

        <div id='staking-card-info-left' style={{width: '100%', height: '170px', background: '#11262E', borderRadius: '10px', boxSizing: 'border-box', padding: '36px 30px 18px 30px'}}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{fontSize: '16px', fontFamily: 'Arial-BoldMT, Arial', color: '#9FA8AB', lineHeight: '18px'}}>RECENT JOLT PROFIT</div>
            <div style={{fontSize: '24px', fontFamily: 'Arial-BoldMT, Arial', lineHeight: '28px'}}>
              { new Decimal(profit).toFixed(4) } <span style={{fontSize: '16px'}}>JOLT</span>
            </div>
          </div>
          <div style={{marginTop: '6px', fontSize: '14px', fontFamily: 'Arial-BoldMT, Arial', color: '#9FA8AB', lineHeight: '16px', textAlign: 'right'}}>
            ≈{ (new Decimal(profit).mul(props.tokenInfo.joltPrice)).toFixed(4)} USD
          </div>
          <div style={{marginTop: '27px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '16px', fontFamily: 'Arial-BoldMT, Arial', color: '#9FA8AB', lineHeight: '18px'}}>
            <div>Interest earned</div>
            <div style={{display: props.userTokenInfo.deltTime>0?'':'none'}}>{ (new Decimal(props.tokenInfo.feePercent).mul(100)).toFixed(2) }% fee apply until</div>
            <div style={{display: props.userTokenInfo.deltTime>0?'none':''}}>0% fee apply</div>
          </div>
          <div style={{marginTop: '6px', fontSize: '16px', fontFamily: 'Arial-BoldMT, Arial', color: '#9FA8AB', lineHeight: '16px', textAlign: 'right'}}>
            {countdownLabels[props.tokenName]}
          </div>
        </div>

        <div style={{width: '0', margin: '0 6.5px 0 6.5px'}}></div>

        <div id='staking-card-info-right-connect' style={{display: !props.account&&(0===Number(props.userTokenInfo.allowance))?'':'none', width: '100%', height: '170px', background: '#11262E', borderRadius: '10px', boxSizing: 'border-box', padding: '36px 30px 18px 30px'}}>
          <div style={{textAlign: 'center', marginBottom: '31px', fontSize: '16px', fontFamily: 'Arial-BoldMT, Arial', color: '#9FA8AB', lineHeight: '18px'}}>You are not connected to a wallet</div>
          <div onClick={()=>{props.notifyFather('connect_bsc'); props.setBscConnecting(true)}} disabled={props.bscConnecting} style={{cursor: 'pointer', userSelect: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40px', background: '#F5841F', borderRadius: '10px', width: '100%', fontSize: '16px', fontFamily: 'PingFangSC-Semibold, PingFang SC', fontWeight: '600', lineHeight: '22px'}}>
            <div style={{marginRight: '13px', width: '20px', height: '20px', backgroundImage: `url(${imgMetamask})`}}></div>
            <div>Connect BSC</div>
          </div>
        </div>

        <div id='staking-card-info-right-approve' style={{display: props.account&&(0===Number(props.userTokenInfo.allowance))?'':'none', width: '100%', height: '170px', background: '#11262E', borderRadius: '10px', boxSizing: 'border-box', padding: '36px 30px 18px 30px'}}>
          <div style={{textAlign: 'center', marginBottom: '31px', fontSize: '16px', fontFamily: 'Arial-BoldMT, Arial', color: '#9FA8AB', lineHeight: '18px'}}>ENABLE JOLT</div>
          <div onClick={()=>enableClicked()} disabled={props.enabling} style={{color: '#213340', cursor: 'pointer', userSelect: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40px', background: 'linear-gradient(233deg, #2AF940 0%, #32FDEF 100%)', borderRadius: '10px', width: '100%', fontSize: '16px', fontFamily: 'PingFangSC-Semibold, PingFang SC', fontWeight: '600', lineHeight: '22px'}}>
            ENABLE
          </div>
        </div>

        <div id='staking-card-info-right-approved' style={{display: Number(props.userTokenInfo.allowance)>0?'':'none', width: '100%', height: '170px', background: '#11262E', borderRadius: '10px', boxSizing: 'border-box', padding: '36px 30px 18px 30px'}}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{fontSize: '16px', fontFamily: 'Arial-BoldMT, Arial', color: '#9FA8AB', lineHeight: '18px'}}>{props.tokenName} STAKED</div>
            <div style={{fontSize: '24px', fontFamily: 'Arial-BoldMT, Arial', lineHeight: '28px'}}>
              { (new Decimal(props.userTokenInfo.staked)).toFixed(4) } <span style={{fontSize: '16px'}}>{props.tokenName}</span>
            </div>
          </div>
          <div style={{marginTop: '6px', fontSize: '14px', fontFamily: 'Arial-BoldMT, Arial', color: '#9FA8AB', lineHeight: '16px', textAlign: 'right'}}>
            ≈{ (new Decimal(props.userTokenInfo.staked).mul(props.tokenInfo.price)).toFixed(4) } USD
          </div>
          <div style={{marginTop: '28px', display: 'flex', justifyContent: 'space-between', fontSize: '12px', fontFamily: 'ArialMT', lineHeight: '14px'}}>
            <div onClick={()=>{
              // depositClick()
            }} style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '200px', height: '40px', background: '#224551', borderRadius: '10px'}}>
              <div style={{marginRight: '6px', width: '18px', height: '18px', backgroundImage: `url(${imgDeposit})`}}></div>
              <div>Deposit</div>
            </div>
            <div onClick={()=>withdrawClick()} style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '200px', height: '40px', background: '#224551', borderRadius: '10px'}}>
              <div style={{marginRight: '6px', width: '18px', height: '18px', backgroundImage: `url(${imgWithdraw})`}}></div>
              <div>Withdraw</div>
            </div>
          </div>
        </div>

      </div>

      <div id="staking-card-footer" style={{userSelect: 'none', display: 'flex', marginTop: '20px', fontSize: '12px', fontFamily: 'ArialMT', color: '#9FA8AB', lineHeight: '14px'}}>
        <div onClick={()=>props.seeTokenInfoClicked(props.tokenName)} style={{width: '160px', height: '30px', background: 'rgba(53, 243, 237, 0.1)', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
          <div style={{marginRight: '10px', width: '12px', height: '12px', backgroundImage: `url(${imgSeeToken})`}}></div>
          <div>See Token Info</div>
        </div>
        <div onClick={()=>props.addToMetamaskClicked(props.tokenName)} style={{marginLeft: '20px', width: '160px', height: '30px', background: 'rgba(53, 243, 237, 0.1)', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
          <div style={{marginRight: '10px', width: '12px', height: '12px', backgroundImage: `url(${imgAddToMetamask})`}}></div>
          <div>Add to Metamask</div>
        </div>
      </div>
    </div>
  )
}

export default Staking