Number.prototype.toFloor = function (decimal) {
  let num = this.toString();
  let index = num.indexOf('.');
  if (index !== -1) {
    num = num.substring(0, decimal + index + 1)
  } else {
    num = num.substring(0)
  }
  return parseFloat(num).toFixed(decimal)
}

String.prototype.toFloor = function (decimal) {
  let numberString = this
  if (isNaN(Number(numberString))) {
    return '0'
  }
  if (-1 === numberString.indexOf('.') || isNaN(parseInt(decimal))) {
    return numberString
  }
  const strings = numberString.split('.')
  if (parseInt(decimal) < 1) {
    return strings[0]
  }
  return strings[0] + '.' + strings[1].substring(0, decimal)
}
