import chainConfigBscMainnet from './chain-config-bsc-mainnet.json'
import chainConfigBscTestnet from './chain-config-bsc-testnet.json'
import chainConfigKeplrMainnet from './chain-config-keplr-mainnet.json'
import chainConfigKeplrTestnet from './chain-config-keplr-testnet.json'

import stakingConfigBscMainnet from './staking-config-bsc-mainnet.json'
import stakingConfigBsctestnet from './staking-config-bsc-testnet.json'

let chainConfigBsc = chainConfigBscTestnet
let chainConfigKeplr = chainConfigKeplrTestnet
let stakingConfigBsc = stakingConfigBsctestnet

if ('mainnet'===process.env.REACT_APP_CHAIN_STATUS) {
  chainConfigBsc = chainConfigBscMainnet
  chainConfigKeplr = chainConfigKeplrMainnet
  stakingConfigBsc = stakingConfigBscMainnet
}

export {
  chainConfigBsc, chainConfigKeplr, stakingConfigBsc
}