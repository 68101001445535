import { useState } from 'react'
import {Link} from 'react-router-dom'

import {Registry} from '@cosmjs/proto-signing'
import {defaultRegistryTypes, SigningStargateClient} from '@cosmjs/stargate'
import {MsgCreateInvoice} from '../../codecs/invoice/tx'
import {chainConfigKeplr} from '../../configs/index'

function CustomProtocol() {
  const [busy, setBusy] = useState(false)
  const keplr = window.keplr
  const execute = async () => {
    if (!keplr) {
      alert('keplr not found'); return
    }
    setBusy(true)
    keplr.enable(chainConfigKeplr.chainId).then(async ()=>{
      const offlineSigner = window.getOfflineSigner(chainConfigKeplr.chainId)
      const accounts = await offlineSigner.getAccounts()
      const account = accounts[0].address

      const registry = new Registry(defaultRegistryTypes)
      registry.register('/joltify.joltifychain.invoice.MsgCreateInvoice', MsgCreateInvoice)
      const client = await SigningStargateClient.connectWithSigner(chainConfigKeplr.rpc, offlineSigner, {registry: registry})

      const message = {
        typeUrl: '/joltify.joltifychain.invoice.MsgCreateInvoice',
        value: MsgCreateInvoice.fromPartial({
          creator: account,
          name: 't' + new Date().getTime(),
          url: 'https://joltify.io',
          amount: '1',
          origOwner: account,
          apy: '20',
          isRootOwner: true
        })
      }
      console.log('message:', message)
      const fee = {
        amount: [], gas: '9000000000000000'
      }
      client.signAndBroadcast(account, [message], fee).then(response=>{
        console.log('result:', response)
        setBusy(false)
      }).catch(()=>{
        setBusy(false)
      })
    }).catch(()=>{
      setBusy(false)
    })
  }
  // const nameChanged = (e) =>{
  //   console.log(e.currentTarget.value)
  // }
  return (
    <div style={{color: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '61.8vh' }}>
      {/* <p>name: <input onChange={(e)=>nameChanged(e)} type="text" value={'t' + new Date().getTime()} /></p>
      <p>url: <input type="text" value="https://joltify.io" /></p>
      <p>amount: <input type="text" value="1" /></p>
      <p>apy: <input type="text" value="20" /></p> */}
      <button onClick={()=>execute()} disabled={busy} style={{fontSize: '2em'}}>Create Invoice</button>
      
      <Link to="/" style={{marginTop: '2em'}}>
        <button style={{fontSize: '2em'}}>Back home</button>
      </Link>
    </div>
  )
}

export default CustomProtocol