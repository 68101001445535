import React, {useState, useEffect} from 'react'
import {decodeBech32Pubkey} from '@cosmjs/amino'
import ethTx2cosmosAddress from '@/extends/bsc-tx-to-cosmos-address.js'
import './Bridge.css'

import {useOutletContext} from 'react-router-dom'

import imgLightning from '@/assets/lightning.gif'
import imgTokenJolt from '@/assets/token-jolt.svg'
import imgTokenBSC from '@/assets/BSC.svg'
// import imageWallet from '@/assets/Wallet.svg'
import imageToggle from '@/assets/toggle.svg'
import imgKeplr1 from '@/assets/Keplr1.svg'
import imgMetamask from '@/assets/metamask.svg'
import imgLoading from '@/assets/loading.gif'
import imgSuccess from '@/assets/success.svg'
import imgCopy from '@/assets/copy.svg'
import imgLoadingDark from '@/assets/loading-dark.gif'

import imgLightningRightTop from '@/assets/lightning-right-top.png'
import imgHands from '@/assets/hands.png'
import imgBgBridge from '@/assets/bg-bridge.png'

import '@/extends/tofloor'
const ethers = require('ethers')
const axios = require('axios')
axios.defaults.timeout = 30000

// const rpc = 'https://rpc.joltify.io'
const rest = 'https://rest.joltify.io'

const Bridge = (props) => {
  props = useOutletContext()
  // console.log('props1', props)

  const [chainsSelected, updateSelectedChains] = useState({from: 'Joltify', to: 'BSC'})
  const [jusdBalance, updateJusdBalance] = useState(ethers.utils.parseEther('0')) // bigNumber
  const [amountTo, updateAmountTo] = useState('0')
  const [valueFrom, updateValueFrom] = useState('0')
  const [bscAccount, updateBscAccount] = useState(undefined)
  const [joltifyAccount, updateJoltifyAccount] = useState(undefined)
  const [showPopup, updateShowPopup] = useState(false)
  const [recepient, updateRecepient] = useState('')
  let clientJoltify
  let jusdContract
  const [loading, updateLoading] = useState(false)
  const [bscConnecting, updateBscConnecting] = useState(false)
  const [joltifyConnecting, updateJoltifyConnecting] = useState(false)
  const [copied, setCopied] = useState(false)
  const [sender, setSender] = useState('')
  const [maxHalfActived, setMaxHalfActived] = useState('') // max, half

  useEffect(()=>{
    // console.log('props2', props)setOutletPassback

    updateJoltifyAccount(props.joltifyChainInfo.account)
    updateBscAccount(props.bscInfo.account)
    clientJoltify = props.joltifyChainInfo.client
    jusdContract = props.bscInfo.jusdContract
    let newJusdBalance = ethers.utils.parseEther('0')
    if ('Joltify'===chainsSelected.from && props.joltifyChainInfo.balances) {
      // console.log('props.joltifyChainInfo.balances', props.joltifyChainInfo.balances)
      newJusdBalance = props.joltifyChainInfo.balances.JUSD
      // console.log('newJusdBalance', newJusdBalance)
      updateRecepient(props.joltifyChainInfo.joltify2bscAddress)
      setSender(props.joltifyChainInfo.account)
    } else if ('BSC'===chainsSelected.from && props.bscInfo.balances) {
      newJusdBalance = props.bscInfo.balances.JUSD
      setSender(props.bscInfo.account)
      const localValue = localStorage.getItem(props.bscInfo.account.toLowerCase())
      if (localValue) {
        updateRecepient(localValue)
      }
    }
    if (!jusdBalance.eq(newJusdBalance)) {
      updateJusdBalance(newJusdBalance)
    }

    if ('Joltify'===chainsSelected.from) {
      if (props.joltifyChainInfo.account) {
        updateJoltifyConnecting(false)
      } else {
        updateJoltifyConnecting(props.joltifyConnecting)
      }
    } else if ('BSC'===chainsSelected.from) {
      if (props.bscInfo.account) {
        updateBscConnecting(false)
      } else {
        updateBscConnecting(props.bscConnecting)
      }
    }
  })

  const tokenIcons = {
    Joltify: imgTokenJolt, BSC: imgTokenBSC
  }
  const toggleClicked = () => {
    if (loading) {
      return
    }
    updateRecepient('')
    setSender('')
    updateSelectedChains({
      from: chainsSelected.to,
      to: chainsSelected.from
    })
    setMaxHalfActived('')
  }
  const updateFromAount = (amount) => {
    document.getElementById('input-from').value = amount
    updateValueFrom(amount)
    updateAmountTo(amount)
    setMaxHalfActived('')
  }
  const inputFromChanged = (e) => {
    let value = e.currentTarget.value
    updateFromAount(value)
  }
  const btnMaxClicked = () => {
    if (loading) {
      return
    }
    const valueString = ethers.utils.formatEther(jusdBalance)
    if ('0.0'===valueString) {
      return
    }
    document.getElementById('input-from').value = valueString
    updateFromAount(ethers.utils.formatEther(jusdBalance))
    setMaxHalfActived('max')
  }
  const btnHalfClicked = () => {
    if (loading) {
      return
    }
    const valueString = ethers.utils.formatEther(jusdBalance.div(2))
    if ('0.0'===valueString) {
      return
    }
    updateFromAount( valueString )
    setMaxHalfActived('half')
  }
  const swapClicked = () => {
    // console.log('swapClicked')
    const inputFrom = document.getElementById('input-from').value
    if (isNaN(Number(inputFrom)) || 0===Number(inputFrom)) {
      return
    }
    const sendAmountBN = ethers.utils.parseEther(inputFrom)
    if (sendAmountBN.gt(jusdBalance)) {
      alert('JUSD send amout larger than balance')
      return
    }
    if ('Joltify'===chainsSelected.from) {
      if (!joltifyAccount || !clientJoltify) {
        return
      }
      if (!props.joltifyChainInfo.balances || props.joltifyChainInfo.balances.JOLT.lt(ethers.utils.parseEther('0.01'))) {
        alert('JOLT(gas) not enough')
        return
      }
    } else if ('BSC'===chainsSelected.from) {
      if (!bscAccount || !jusdContract) {
        return
      }
      if (!props.bscInfo.balances || props.bscInfo.balances.BNB.lt(ethers.utils.parseEther('0.01'))) {
        alert('JOLT(gas) not enough')
        return
      }
    }

    updateLoading(true)
    axios.get(`${rest}/joltify/joltifyChain/vault/getLastPool`).then(async ({data})=>{
      const res = data
      let poolPubKey = res.pools[0].CreatePool.poolPubKey
      // console.log('poolPubKey', poolPubKey); return
      let poolAddressJoltify = res.pools[0].CreatePool.poolAddr
      if ( Number(res.pools[0].BlockHeight) < Number(res.pools[1].BlockHeight) ) {
        poolPubKey = res.pools[1].CreatePool.poolPubKey
        poolAddressJoltify = res.pools[1].CreatePool.poolAddr
      }
      const bech32Pubkeydecoded = decodeBech32Pubkey(poolPubKey)
      const hexlify = ethers.utils.hexlify( ethers.utils.base64.decode(bech32Pubkeydecoded.value) )
      const poolAddressEth = ethers.utils.computeAddress( hexlify )
      console.log('poolAddressEth', poolAddressEth)

      if ('Joltify'===chainsSelected.from) {
        const amount = [
          {denom: 'JOLT', amount: ethers.utils.parseEther('0.01').toString()},
          {denom: 'JUSD', amount: sendAmountBN.toString()}
        ]
        clientJoltify.sendTokens(joltifyAccount, poolAddressJoltify, amount, {amount: [], gas: '9000000000000000'}).then(result=>{
          console.log('Joltify sendTokens result ', result)
          if (0===result.code) {
            props.notifyFather('balance_changed')
            // props.setOutletPassback('balance_changed')
            
            // console.log('tx info:', `${rpc}/tx?hash=${result.transactionHash}&prove=true`)
            // updateRecepient(props.joltifyChainInfo.joltify2bscAddress)
            // alert(`${inputFrom} JUSD sent to BSC address: ${props.joltifyChainInfo.joltify2bscAddress}`)
            updateShowPopup(true)
            setMaxHalfActived('')
          } else {
            alert(`error code ${result.code}`)
          }
        }).finally(()=>{
          updateLoading(false)
        })
      } else if ('BSC'===chainsSelected.from) {
        // const transferRes = await jusdContract.transfer(poolAddressEth, sendAmountBN)
        jusdContract.transfer(poolAddressEth, sendAmountBN).then(async res=>{
          // console.log('JUSD transfer result', res) // use this tx to calculate joltify address
          const recepientNow = await ethTx2cosmosAddress(res, 'jolt')
          // set localStorage
          localStorage.setItem(res.from.toLowerCase(), recepientNow)
          updateRecepient(recepientNow)
          props.bscInfo.bscSigner.sendTransaction({
            to: poolAddressEth,
            value: ethers.utils.parseEther('0.001'),
            data: res.hash
          }).then(res=>{
            res.wait().then(res=>{
              props.notifyFather('balance_changed')
              // props.setOutletPassback('balance_changed')
              // console.log('BNB transfer result', res)
              // alert(`Sent ${inputFrom} JUSD to Joltify address: ${recepientNow}`) // show address here
              updateShowPopup(true)
              setMaxHalfActived('')
            }).catch(error=>{
              console.error('sendTransaction wait error', error)
            }).finally(()=>{
              updateLoading(false)
            })
          }).catch(error=>{
            console.error('sendTransaction error', error)
          })
        }).catch(error=>{
          console.error('jusdContract.transfer error', error)
        })
      }
    }).catch(error=>{
      updateLoading(false)
      alert('someting went wrong, code 1948')
      console.log('fetch pool address error', `${rest}/joltify/joltifyChain/vault/getLastPool`, error)
    })
  }

  const copyText = (text) => {
    if (!window.navigator.clipboard) {
      return
    }
    window.navigator.clipboard.writeText(text).then(()=>{
      setCopied(true)
      setTimeout(()=>{
        setCopied(false)
      }, 2000)
    }, (error)=>{
      console.error('error', error)
    })
  }
  return (
    <>
      <div id='bridge-view' style={{backgroundImage: 'url('+imgBgBridge+')'}}>

        <div style={{ backgroundColor: 'rgba(33, 51, 64, 0.5)', padding: '10px', borderRadius: '15px', boxShadow: 'inset 0px 1px 3px 0px rgba(235, 222, 222, 0.5)' }}>
          <div style={{ backgroundColor: '#213340', padding: '30px', borderRadius: '15px', position: 'relative' }}>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <div id='icon-seting'></div>
            </div>

            <div id='session-from'>
              <div id="from-top-label" style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div className='label-left'>From</div>
                <div style={{marginLeft: '8px', fontSize: '12px', fontFamily: 'ArialMT', color: '#828D92', lineHeight: '14px'}}>
                  {sender?(sender.substring(0, 8) + '....' +  sender.substring(sender.length-8, sender.length)):''}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', flexGrow: '1', flexDirection: 'row' }}>
                  <div id='label-available' style={{ marginRight: '9px' }}>Available</div>
                  <div id='label-available-amount' style={{ marginRight: '19px' }}>{
                    jusdBalance.eq(ethers.utils.parseEther('0'))?'0':(ethers.utils.formatEther(jusdBalance).toFloor(4))
                  } JUSD</div>
                  <div onClick={()=>btnMaxClicked()} highlight={'max'===maxHalfActived?1:0} id='btn-max' style={{ marginRight: '10px', userSelect: 'none' }}>MAX</div>
                  <div onClick={()=>btnHalfClicked()} highlight={'half'===maxHalfActived?1:0} id='btn-half' style={{userSelect: 'none'}} >HALF</div>
                </div>
              </div>
              <div id='from-input-currency' style={{display: 'flex', flexDirection: 'row', marginTop: '18px', alignItems: 'center'}}>
                <img src={tokenIcons[chainsSelected.from]} style={{height: '40px', width: '40px', marginRight: '17px'}} alt="" />
                <div style={{fontSize: '20px', color: '#FFFFFF', fontFamily: 'Arial-BoldMT, Arial'}}>
                  {chainsSelected.from}
                </div>
                <div style={{flexGrow: '1', textAlign: 'right'}}>
                  <input disabled={loading} onChange={(e)=>inputFromChanged(e)} id="input-from" style={{width:'100%', border: '0', outline: 'none', backgroundColor: 'transparent',
                   color: valueFrom&&jusdBalance.lt(ethers.utils.parseEther(valueFrom.toFloor(18).toString()))?'#ff0000':'#ffffff',
                   textAlign: 'right', fontSize: '22px', fontFamily: 'Arial-BoldMT, Arial'}} type="number" placeholder="0" />
                </div>
              </div>
              <div id='from-token-value' style={{textAlign: 'right', fontSize: '14px', fontFamily: 'ArialMT', color: '#9FA8AB', marginTop: '2px'}}>
                ≈ ${valueFrom?valueFrom:0}
              </div>
            </div>

            <img onClick={()=>toggleClicked()} src={imageToggle} alt="" style={{width: '36px', height: '40px', position: 'absolute', top: '219px', left: '282px', cursor: 'pointer'}} />

            <div id='session-to'>
              <div id="from-top-label" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height:'20px' }}>
                <div className='label-left'>To</div>
                <div style={{marginLeft: '8px', fontSize: '12px', fontFamily: 'ArialMT', color: '#828D92', lineHeight: '14px'}}>
                  {recepient}
                </div>
              </div>
              <div id='from-input-currency' style={{display: 'flex', flexDirection: 'row', marginTop: '18px', alignItems: 'center'}}>
                <img src={tokenIcons[chainsSelected.to]} style={{height: '40px', width: '40px', marginRight: '17px'}} alt="" />
                <div style={{fontSize: '20px', color: '#FFFFFF', fontFamily: 'Arial-BoldMT, Arial'}}>
                  {chainsSelected.to}
                </div>
                <div style={{flexGrow: '1', textAlign: 'right', fontSize: '22px', fontFamily: 'Arial-BoldMT, Arial', color: '#ffffff'}}>
                  ≈ {amountTo?amountTo:0} JUSD
                </div>
              </div>
              <div style={{textAlign: 'right', fontSize: '14px', fontFamily: 'ArialMT', color: '#9FA8AB', marginTop: '2px', height: '16px'}}></div>
            </div>

            <div id='session-rate'>
              <div style={{display:'flex', flexDirection:'row', alignItems: 'center', fontSize: '14px', fontFamily: 'ArialMT', color: '#9FA8AB'}}>
                <div >Rate</div>
                <div style={{flexGrow: '1', textAlign: 'right'}}>1 JUSD in Joltify = 1 JUSD in BSC</div>
              </div>
              <div style={{textAlign: 'right', marginTop: '10px', fontSize: '12px', fontFamily: 'ArialMT', color: '#9FA8AB', opacity: '0.8'}}>1 JUSD in BSC = 1 JUSD in Joltify</div>
              <div style={{display:'flex', flexDirection:'row', marginTop: '20px', alignItems: 'center', fontSize: '14px', fontFamily: 'ArialMT', color: '#9FA8AB'}}>
                <div>Swap Fee</div>
                <div style={{flexGrow: '1', textAlign: 'right', fontSize: '14px', fontFamily: 'ArialMT', color: '#9FA8AB'}}>{'Joltify'===chainsSelected.from?'0.01 JOLT':'0.001 BNB'}</div>
              </div>
              <div style={{height: '1px', backgroundColor: '#ffffff', opacity: '0.07', marginTop: '18px'}}></div>
              <div style={{display:'flex', flexDirection:'row', marginTop: '17px', alignItems: 'center', fontFamily: 'Arial-BoldMT, Arial', fontSize: '14px', color: '#ffffff'}}>
                <div>Estimated Slippage</div>
                <div style={{flexGrow: '1', textAlign: 'right'}}>0%</div>
              </div>
            </div>

            <div onClick={()=>swapClicked()} id='btn-bridge' disabled={
              !joltifyAccount&&'Joltify'===chainsSelected.from || !bscAccount&&'BSC'===chainsSelected.from || loading
              || jusdBalance.eq(ethers.utils.parseEther('0')) || valueFrom&&jusdBalance.lt(ethers.utils.parseEther(valueFrom.toFloor(18).toString()))
              || 0===Number(valueFrom)
            } style={{display: (joltifyAccount&&'Joltify'===chainsSelected.from)||(bscAccount&&'BSC'===chainsSelected.from)?'':'none'}}
            >
              <div style={{fontSize: '16px', color:'#213340', display: 'flex', alignItems: 'center'}}>
                <img style={{display: loading?'':'none', height: '20px', marginRight: '20px'}} src={imgLoadingDark} alt="" />
                Swap
              </div>
            </div>

            <div onClick={()=>{props.notifyFather('connect_joltify'); updateJoltifyConnecting(true)}} style={{
                display: !joltifyAccount&&'Joltify'===chainsSelected.from?'':'none',
                opacity: joltifyConnecting?'0.5':'1'
              }} id='btn-connect-joltify'>
              <img src={joltifyConnecting?imgLoading:imgKeplr1} alt="" style={{height: '20px', marginRight: '13px'}} />
              <div>Connect Joltify</div>
            </div>

            <div onClick={()=>{props.notifyFather('connect_bsc'); updateBscConnecting(true)}} style={{
                display: !bscAccount&&'BSC'===chainsSelected.from?'':'none',
                opacity: bscConnecting?'0.5':'1'
              }} id='btn-connect-bsc-bridge'>
              <img src={bscConnecting?imgLoading:imgMetamask} alt="" style={{height: '20px', marginRight: '13px'}} />
              <div>Connect BSC</div>
            </div>

          </div>
        </div>

      </div>

      <img id='img-lightning-right-top' src={imgLightningRightTop} alt="" />
      <div id='hands-lightning' style={{backgroundImage: `url(${imgHands})`}}>
        <img id='img-lightning' src={imgLightning} alt="" />
      </div>

      <div id='success-popup' style={{
        width: '100%', height: '100vh',
        position: 'absolute', zIndex: '65535', left: '0', top: '0',
        backgroundColor: 'rgba(32, 32, 32, 0.7)', color: '#ffffff',
        display: showPopup?'flex':'none', alignItems: 'center', justifyContent: 'center'
      }}>
        <div id='popup-body' style={{
          width: '500px', 
          background: '#273C4B', borderRadius: '8px',
          border: '1px solid #455961', boxSizing: 'border-box'
        }}>
          {/* <div id='pupup-top' style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: '24px'}}>
            <div style={{width: '100px'}}></div>
            <div style={{flexGrow: '1', fontSize: '22px', fontFamily: 'Arial-BoldMT, Arial', fontWeight: 'normal', color: '#FFFFFF', lineHeight: '26px', textAlign: 'center'}}>Success</div>
            <div style={{width: '100px', display: 'flex', alignItems: 'center', paddingRight: '27px', justifyContent: 'right', cursor: 'pointer', boxSizing: 'border-box'}}></div>
          </div> */}

          <div style={{textAlign: 'center'}}>
            <img src={imgSuccess} style={{height: '80px', marginTop: '51px'}} alt="" />
          </div>
          

          {/* <div id="popup-hr-line" style={{height: '2px', width: '100%', opacity: '0.24', background:'#455961', marginTop: '20px'}}></div> */}

          <div id='pupup-content' style={{boxSizing: 'border-box', margin: '58px 27px 70px 27px', textAlign: 'center'}}>
            <div style={{fontFamily: 'Helvetica', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <div style={{fontSize: '30px', alignItems: 'flex-end', display: 'flex', lineHeight: '26px'}}>
                {amountTo}
              </div>
              <div style={{alignItems: 'flex-end', display: 'flex', fontSize: '16px', color: '#9FA8AB', paddingLeft: '7px'}}>
                JUSD
              </div>
            </div>
            <div style={{marginTop: '26px', fontSize: '12px', fontFamily: 'ArialMT', color: '#9FA8AB', lineHeight: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              Transferred to
              <span style={{color: '#35F3ED', marginLeft: '4px'}}>
                {recepient}
              </span>
              <span style={{display: copied?'':'none', marginLeft: '4px'}}>Copied</span>
              <img onClick={()=>copyText(recepient)} src={imgCopy} style={{marginLeft: '4px', display: copied?'none':'', height: '12px', cursor: 'pointer'}} alt="" />
            </div>
          </div>

          <div id='pupup-btns' style={{userSelect: 'none', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '32px', fontSize: '16px', fontFamily: 'PingFangSC-Semibold, PingFang SC', fontWeight: '600'}}>
            <div onClick={()=>updateShowPopup(false)} id='popup-btn-left' style={{cursor: 'pointer', alignItems: 'center', display: 'flex', justifyContent: 'center', width: '180px', height: '40px', borderRadius: '10px', border: '1px solid #9FA8AB', boxSizing: 'border-box', color: '#9FA8AB'}}>
              Close  
            </div>
            {/* <div id='popup-btn-left' style={{marginLeft: '35px', cursor: 'pointer', alignItems: 'center', display: 'flex', justifyContent: 'center', width: '180px', height: '40px', borderRadius: '10px', boxSizing: 'border-box', background: 'linear-gradient(233deg, #2AF940 0%, #32FDEF 100%)', color: '#213340'}}>
              View details
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}
export default Bridge