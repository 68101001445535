import React, { useState } from 'react'
import {useParams, Link} from 'react-router-dom'

import './InvestPool.css'

import imgDAI from '../../assets/DAI.svg'
import imgSenior from '../../assets/Senior.svg'
import imgJunior from '../../assets/Junior.svg'
import imgExampleBranch from '../../assets/example-branch.png'
import imgContact from '../../assets/Contact.svg'
import imgExecutiveSummary from '../../assets/Executive-Summary.svg'
import imgWebsite from '../../assets/Website.svg'
import imgEye from '../../assets/eye-line.svg'
import imgWarning from '../../assets/warning.svg'
import imgTokenJolt from '@/assets/token-jolt.svg'

const detailsExamples = [
  {
    icon: 'https://is2-ssl.mzstatic.com/image/thumb/Purple124/v4/11/05/72/11057214-0cd2-a50d-3cea-fc44895f0345/source/256x256bb.jpg',
    title: 'Joltify Solar Farm',
    type: 'Solar Power Plant LLC',
    capacity: '4.00M DAI'
  },
  {
    icon: 'https://is5-ssl.mzstatic.com/image/thumb/Purple118/v4/6c/ad/c9/6cadc9e7-1380-84f5-0eff-b5ff8e143eba/source/256x256bb.jpg',
    title: 'Joltify Building LLC',
    type: 'Commercial Development Asset-Based Loan',
    capacity: '5.00M DAI'
  },
  {
    icon: 'https://img1.mydrivers.com/img/20210320/49b220435b3c4a95acac7e3b021645ff.jpg',
    title: 'Joltify Car Company',
    type: 'Cash Flow-Based Loan',
    capacity: '6.00M DAI'
  }
]

function InvestPool() {
  const params = useParams()
  let poolId = parseInt(params.poolId)
  if (isNaN(poolId) || poolId>5) {
    poolId = 0
  }
  if (poolId>2) {
    poolId = poolId-3
  }
  const poolDetails = detailsExamples[poolId]

  const [showInput, setShowInput] = useState(false)

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div style={{width: '100%', maxWidth: '1080px', display: 'flex', flexDirection: 'column', paddingTop: '71px', paddingBottom: '34px'}}>

        <div id='pool-header-session-1' style={{height: '62px', marginBottom: '39px', display: 'flex'}}>
          <div id='pool-logo' style={{backgroundImage: 'url('+poolDetails.icon+')', backgroundSize: 'cover', width: '60px', height: '60px', borderRadius: '999vh'}}></div>
          <div id='pool-title-value' style={{marginLeft: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div id='pool-title' style={{fontSize: '20px', fontFamily: 'Arial-BoldMT, Arial', fontWeight: 'normal', color: '#FFFFFF', lineHeight: '23px'}}>{poolDetails.title}</div>
            <div id='pool-value' style={{alignSelf: 'baseline', borderRadius: '999vh', border: '1px solid #35F3ED', padding: '4px 10px', fontSize: '12px', fontFamily: 'PingFangSC-Regular, PingFang SC', fontWeight: '400', color: '#35F3ED', lineHeight: '15px'}}>
              4.00M DAO
            </div>
          </div>
        </div>

        <div style={{display: 'flex', fontSize: '12px', fontFamily: 'ArialMT', color: '#9FA8AB', lineHeight: '14px'}}>

          <div style={{display: 'flex', flexDirection: 'column', marginRight: '10px', boxSizing: 'border-box', width: '50%', padding: '50px 20px 20px 20px', background: '#213340', borderRadius: '10px'}}>
            
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{backgroundImage: 'url('+imgSenior+')', width: '20px', height:'20px'}}></div>
              <div style={{marginLeft: '13px', fontSize: '16px', fontFamily: 'Arial-BoldMT, Arial', fontWeight: 'normal', color: '#FFFFFF', lineHeight: '18px'}}>Senior tranche</div>
            </div>
            <div style={{marginBottom: '20px', marginTop: '8px'}}>A token — Lower risk, stable return</div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div>Your balance</div>
              <div><span style={{color: '#FFFFFF'}}>0.0000</span> A Token</div>
            </div>

            <div style={{background: '#293C43', height: '1px', margin: '12px 0 13px 0'}}></div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div>Current price</div>
              <div><span style={{color: '#FFFFFF'}}>1.0753</span></div>
            </div>

            <div style={{background: '#293C43', height: '1px', margin: '12px 0 13px 0'}}></div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div>Current value</div>
              <div><span style={{color: '#FFFFFF'}}>0.0000</span> DAI</div>
            </div>

            <div style={{display: showInput?'':'none', marginTop: '36px'}}>
              <div style={{marginBottom: '16px', fontSize: '14px', color: '#ffffff', fontFamily: 'Arial-BoldMT, Arial'}}>Enter your investment amount below</div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <input onChange={(e)=>console.log(e.currentTarget.value)} value="0.000000000000000000" style={{fontSize: '14px', color: '#ffffff', flexGrow: '1', border: '0', outline: 'none', backgroundColor: 'transparent'}} type="text" />
                <div style={{width: '1px', height: '18px', background: '#293C43'}}></div>
                <div style={{marginLeft: '14px', backgroundImage: 'url('+imgDAI+')', width: '14px', height: '14px', backgroundSize: 'cover'}}></div>
                <div style={{marginLeft: '4px'}}>DAI</div>
              </div>
              <div style={{background: '#293C43', height: '2px', margin: '9px 0 6px 0'}}></div>
              <div>Your balance：0.000000000000000000</div>
            </div>

            <div style={{display: showInput?'flex':'none', flexGrow: '1', alignItems: 'flex-end', marginTop: '20px'}}>
              <div style={{display: 'flex', alignItems: 'center', flexGrow: '1'}}>
                <div style={{display: 'flex', justifyContent: 'flex-end', flexGrow: '1', fontSize: '16px'}}>
                  <div onClick={()=>setShowInput(false)} style={{userSelect: 'none', marginRight: '23px', width: '110px', height: '40px', borderRadius: '10px', border: '1px solid #9FA8AB', boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', fontFize: '16px', fontFamily: 'Arial-BoldMT, Arial', color: '#9FA8AB', cursor: 'pointer'}}>
                    Cancel
                  </div>
                  <div style={{userSelect: 'none', width: '110px', height: '40px', background: 'linear-gradient(233deg, #2AF940 0%, #32FDEF 100%)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'PingFangSC-Semibold, PingFang SC', fontWeight: '600', color: '#213340', lineHeight: '22px', cursor: 'pointer'}}>
                    Lock DAI
                  </div>
                </div>
              </div>
            </div>

            <div style={{display: !showInput?'flex':'none', flexGrow: '1', alignItems: 'flex-end', marginTop: '20px'}}>
              <div style={{display: 'flex', alignItems: 'center', flexGrow: '1'}}>
                <div id='display-in-wallet' style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                  <div style={{width: '18px', height: '16px', backgroundImageSize: 'cover', backgroundImage: 'url('+imgEye+')', marginRight: '10px'}}></div>
                  <div>Display in wallet</div>
                </div>
                <div style={{fontSize: '16px', display: 'flex', justifyContent: 'flex-end', flexGrow: '1'}}>
                  <div style={{userSelect: 'none', marginRight: '23px', width: '110px', height: '40px', borderRadius: '10px', border: '1px solid #9FA8AB', boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', fontFize: '16px', fontFamily: 'Arial-BoldMT, Arial', color: '#9FA8AB', cursor: 'pointer'}}>Redeem</div>
                  <div onClick={()=>setShowInput(true)} style={{userSelect: 'none', width: '110px', height: '40px', background: 'linear-gradient(233deg, #2AF940 0%, #32FDEF 100%)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'PingFangSC-Semibold, PingFang SC', fontWeight: '600', color: '#213340', lineHeight: '22px', cursor: 'pointer'}}>Invest</div>
                </div>
              </div>
            </div>

          </div>

          <div style={{marginLeft: '10px', boxSizing: 'border-box', width: '50%', padding: '50px 20px 20px 20px', background: '#213340', borderRadius: '10px'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{backgroundImage: 'url('+imgJunior+')', width: '20px', height:'20px'}}></div>
              <div style={{marginLeft: '13px', fontSize: '16px', fontFamily: 'Arial-BoldMT, Arial', fontWeight: 'normal', color: '#FFFFFF', lineHeight: '18px'}}>Junior tranche</div>
            </div>
            <div style={{marginBottom: '20px', marginTop: '8px'}}>B token — Higher risk, variable return</div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div>Your balance</div>
              <div><span style={{color: '#FFFFFF'}}>0.0000</span> A Token</div>
            </div>

            <div style={{background: '#293C43', height: '1px', margin: '12px 0 13px 0'}}></div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div>Current price</div>
              <div><span style={{color: '#FFFFFF'}}>1.0753</span></div>
            </div>

            <div style={{background: '#293C43', height: '1px', margin: '12px 0 13px 0'}}></div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div>Current value</div>
              <div><span style={{color: '#FFFFFF'}}>0.0000</span> DAI</div>
            </div>

            <div style={{lineHeight: '20px', background: '#213844', borderRadius: '10px', border: '1px solid #224551', padding: '14px 16px', marginTop: '68px'}}>
              <div style={{lineHeight: '26px', fontSize: '16px', color: '#ffffff'}}>Interested in investing?</div>
              <div style={{fontSize: '12px'}}>B tokens usually have higher yet more volatile returns, limited liquidity and require a minimum investment amount of 50k DAI. If you are interested in investing in B Token, please <span style={{color: '#35F3ED', cursor: 'pointer'}}>contact the issuer</span> </div>
            </div>
          </div>
        </div>

        <div style={{fontFamily: 'ArialMT', marginTop: '20px', boxSizing: 'border-box', padding: '20px', background: '#213340', borderRadius: '10px', color: '#9FA8AB', fontSize: '12px'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{backgroundImage: 'url('+imgWarning+')', height: '18px', width: '18px', backgroundImageSize: 'cover'}}></div>
            <div style={{margin: '0 11px 0 8px', fontSize: '16px', fontFamily: 'Arial-BoldMT, Arial', fontWeight: 'normal', color: '#FFFFFF', lineHeight: '18px'}}>
              Epoch 68 — Minimum duration ended
            </div>
            <div>Locked orders cannot be executed</div>
          </div>
          
          <div style={{marginTop: '11px', padding: '17px 20px', background: '#11262E', borderRadius: '10px'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{flexGrow: '1'}}>Investments</div>
              <div className='invest-td-2'>Locked</div>
              <div className='invest-td-3'>To be executed</div>
              <div className='invest-td-4'>In %</div>
            </div>
            <div style={{background: '#293C43', height: '1px', margin: '12px 0 13px 0'}}></div>

            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{flexGrow: '1'}}>A investments</div>
              <div className='invest-td-2'>0 DAI</div>
              <div className='invest-td-3'>0 DAI</div>
              <div className='invest-td-4'>0 %</div>
            </div>
            <div style={{background: '#293C43', height: '1px', margin: '12px 0 13px 0'}}></div>

            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{flexGrow: '1'}}>B investments</div>
              <div className='invest-td-2'>0 DAI</div>
              <div className='invest-td-3'>0 DAI</div>
              <div className='invest-td-4'>0 %</div>
            </div>
            <div style={{background: '#293C43', height: '1px', margin: '12px 0 13px 0'}}></div>

            <div style={{display: 'flex', alignItems: 'center', fontSize: '14PX', fontFamily: 'Arial-BoldMT, Arial', color: '#FFFFFF', lineHeight: '16px'}}>
              <div style={{flexGrow: '1'}}>Total pending investments</div>
              <div className='invest-td-2'>0 DAI</div>
              <div className='invest-td-3'>0 DAI</div>
              <div className='invest-td-4'>0 %</div>
            </div>
          </div>

          <div style={{marginTop: '11px', padding: '17px 20px', background: '#11262E', borderRadius: '10px'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{flexGrow: '1'}}>Redemptions</div>
              <div className='invest-td-2'>Locked</div>
              <div className='invest-td-3'>To be executed</div>
              <div className='invest-td-4'>In %</div>
            </div>
            <div style={{background: '#293C43', height: '1px', margin: '12px 0 13px 0'}}></div>

            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{flexGrow: '1'}}>A investments</div>
              <div className='invest-td-2'>552,775 DAI</div>
              <div className='invest-td-3'>0 DAI</div>
              <div className='invest-td-4'>0 %</div>
            </div>
            <div style={{background: '#293C43', height: '1px', margin: '12px 0 13px 0'}}></div>

            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{flexGrow: '1'}}>B Token investments</div>
              <div className='invest-td-2'>0 DAI</div>
              <div className='invest-td-3'>0 DAI</div>
              <div className='invest-td-4'>0 %</div>
            </div>
            <div style={{background: '#293C43', height: '1px', margin: '12px 0 13px 0'}}></div>

            <div style={{display: 'flex', alignItems: 'center', fontSize: '14PX', fontFamily: 'Arial-BoldMT, Arial', color: '#FFFFFF', lineHeight: '16px'}}>
              <div style={{flexGrow: '1'}}>Total pending investments</div>
              <div className='invest-td-2'>552,775 DAI</div>
              <div className='invest-td-3'>0 DAI</div>
              <div className='invest-td-4'>0 %</div>
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default InvestPool