import { Routes, Route } from 'react-router-dom'
import Home from '@/components/Home/Home'
import Pools from '@/components/Pools/Pools'
import Bridge from '@/components/Bridge/Bridge'
// import Assets from '@/components/Assets/Assets'
import Staking from './components/Staking/Staking'
import PoolDetails from '@/components/PoolDetails/PoolDetails'
import InvestPool from '@/components/InvestPool/InvestPool'
import CustomProtocol from './components/Dev/CustomProtocol'

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />}>
          {/* <Route path='bridge' element={<Bridge />} /> */}
          {/* <Route path='pools' element={<Pools />} /> */}
          <Route index element={<Staking />} />
          {/* <Route path='pool/:poolId' element={<PoolDetails />} /> */}
          {/* <Route path='pool/:poolId/investments' element={<InvestPool />} /> */}
        </Route>
        <Route path='/dev/custom-protocol' element={<CustomProtocol />} />
      </Routes>
    </>
  )
}

export default App